// src/pages/admin/CarteiraAdministrada.jsx
import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ModuleCard from '../../components/ModuleCard'; // Importe o componente de card
import { adminMenuItems } from '../../routes/adminRoutes'; // Importa as rotas administrativas
import ArticleSharpIcon from '@mui/icons-material/ArticleSharp';
import SettingsIcon from '@mui/icons-material/Settings';
import WarningIcon from '@mui/icons-material/Warning';
import CommentIcon from '@mui/icons-material/Comment';

const CarteiraAdministrada = ({ cardsPerRow = {  sm: 1, md: 2 } }) => {
  const theme = useTheme();

  // Encontra o módulo "Carteira Administrada" em adminMenuItems
  const carteiraModule = adminMenuItems.find(item => item.text === 'Carteira Administrada');
  const submodules = carteiraModule?.submodules || [];

  // Define os ícones específicos para cada submódulo
  const submoduleIcons = {
    'Relatório': <ArticleSharpIcon sx={{ fontSize: 60 }} />,
    'Comentário Gestor': <CommentIcon sx={{ fontSize: 60 }} />,
    'Logs': <SettingsIcon sx={{ fontSize: 60 }} />,
    'Não Enviar': <WarningIcon sx={{ fontSize: 60 }} />,
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
        Carteira Administrada
      </Typography>

      {/* Grid layout para os cards */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            sm: `repeat(${cardsPerRow.sm}, 1fr)`,
            md: `repeat(${cardsPerRow.md}, 1fr)`,
          },
          gap: 2,
          paddingX: { xs: 2, md: 6 },
        }}
      >
        {submodules.map((submodule, index) => (
          <ModuleCard
            key={index}
            title={submodule.text}
            description={`Acesse o módulo ${submodule.text}.`}
            icon={submoduleIcons[submodule.text] || <ArticleSharpIcon sx={{ fontSize: 60 }} />} // Ícone dinâmico baseado no nome do submódulo
            route={submodule.route}
          />
        ))}
      </Box>
    </Box>
  );
};

export default CarteiraAdministrada;
