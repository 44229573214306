import React, { useState, useEffect } from 'react';
import { Box, Typography, Button ,Tooltip, IconButton} from '@mui/material';
import { fetchNaoEnviar, sendMoverClienteNaoEnviar, uploadFileMoverClienteNaoEnviar } from '../../services/clientes';
import DataTable from '../../components/DataTable';
import CustomSnackbar from '../../components/CustomSnackbar';
import LoadingIndicator from '../../components/LoadingIndicator';
import RemoveButton from '../../components/ActionButton/RemoveButton';
import CustomDialog from '../../components/Modal/CustomDialog';
import InfoIcon from '@mui/icons-material/Info';
import FullScreenLoader from '../../components/FullScreenLoader'; // Importando o loader

const CarteiraNaoEnviar = () => {
    const [naoEnviar, setNaoEnviar] = useState([]);
    const [loading, setLoading] = useState(true);
    const [uploadLoading, setUploadLoading] = useState(false); // Estado para o FullScreenLoader
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success', title: '' });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [clienteSelecionado, setClienteSelecionado] = useState(null);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [uploadResults, setUploadResults] = useState({ success: [], error: [] });
    const [instructionsDialogOpen, setInstructionsDialogOpen] = useState(false);

    useEffect(() => {
        const loadNaoEnviar = async () => {
            try {
                const data = await fetchNaoEnviar();
                setNaoEnviar(data);
            } catch (error) {
                setSnackbar({
                    open: true,
                    message: "Erro ao carregar os dados. Tente novamente mais tarde.",
                    severity: "error",
                    title: "Erro",
                    position: { vertical: 'top', horizontal: 'center' }
                });
            } finally {
                setLoading(false);
            }
        };

        loadNaoEnviar();
    }, []);

    // Estado do Dialog do Remove Button
    const handleOpenDialog = (cliente) => {
        setClienteSelecionado(cliente);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setClienteSelecionado(null);
    };

    const handleConfirmRemove = async () => {
        if (!clienteSelecionado) return;

        try {
            await sendMoverClienteNaoEnviar(clienteSelecionado.cpf_cnpj);
            setNaoEnviar((prev) => prev.filter((cliente) => cliente.cpf_cnpj !== clienteSelecionado.cpf_cnpj));
            setSnackbar({
                open: true,
                message: `Cliente ${clienteSelecionado.nome_cliente} (${clienteSelecionado.cpf_cnpj}) movido com sucesso para 'Enviar Relatório'.`,
                severity: "success",
                title: "Sucesso",
                position: { vertical: 'top', horizontal: 'center' }
            });
        } catch (error) {
            setSnackbar({
                open: true,
                message: `Erro ao mover o cliente ${clienteSelecionado.nome_cliente}.`,
                severity: "error",
                title: "Erro",
                position: { vertical: 'top', horizontal: 'center' }
            });
        } finally {
            handleCloseDialog();
        }
    };
    
    // Estado do Dialog do Upload File 
    const handleCloseUploadDialog = () => {
        setUploadDialogOpen(false);
    };
    
    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setUploadLoading(true); // Exibe o Loader
    
        try {
            const response = await uploadFileMoverClienteNaoEnviar(file);
    
            // Definir os resultados do upload para o dialog
            setUploadResults({
                success: response.cpfs_movidos || [],
                error: response.cpfs_nao_encontrados || []
            });
            setUploadDialogOpen(true);
    
            // Atualiza a tabela após o upload
            const updatedData = await fetchNaoEnviar();
            setNaoEnviar(updatedData);
        } catch (error) {
            setUploadResults({
                success: [],
                error: [error.message || "Erro ao processar o arquivo."]
            });
            setUploadDialogOpen(true);
        } finally {
            setUploadLoading(false); // Oculta o loader
            // Redefinir o valor do input de arquivo
            event.target.value = null;
        }
    };
    
    const columns = [
        { field: 'nome_cliente', headerName: 'Nome do Cliente', headerAlign: 'center', flex: 1 },
        { field: 'cpf_cnpj', headerName: 'CPF/CNPJ', headerAlign: 'center', flex: 1 },
        {
            field: 'acoes',
            headerName: 'Ações',
            headerAlign: 'center',
            align: 'center',
            flex: 0.5,
            renderCell: (params) => (
                <RemoveButton
                    onRemove={() => handleOpenDialog(params.row)}
                    nome={params.row.nome_cliente}
                />
            ),
        },
    ];

    // Estado do icone de instruções
    const handleOpenInstructionsDialog = () => {
        setInstructionsDialogOpen(true);
    };

    const handleCloseInstructionsDialog = () => {
        setInstructionsDialogOpen(false);
    };
    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom>Clientes - Não Enviar Relatório</Typography>

            {loading ? (
                <LoadingIndicator message="Carregando dados dos clientes..." />
            ) : (
                <DataTable
                    columns={columns}
                    rows={naoEnviar}
                    idColumn="cpf_cnpj"
                    loading={loading}
                />
            )}

            {/* Botão para Upload de Arquivo */}
            <Box mt={4} display="flex" justifyContent="flex" alignItems="center" gap={2}>
                <Button
                    variant="contained"
                    component="label"
                    color="primary"
                >
                    Upload Arquivo Mover Clientes
                    <input
                        type="file"
                        hidden
                        accept=".csv, .xlsx, .xls"
                        onChange={handleFileUpload}
                    />
                </Button>
                <Tooltip title="Instruções para o arquivo">
                    <IconButton onClick={handleOpenInstructionsDialog}>
                        <InfoIcon color="secondary" />
                    </IconButton>
                </Tooltip>
            </Box>

            {/* FullScreenLoader */}
            <FullScreenLoader message="Processando arquivo..." visible={uploadLoading} />
            
            {/* CustomDialog para confirmação */}
            <CustomDialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmRemove}
                title={
                    <Box display="flex" alignItems="center">
                        Mover Cliente para Enviar Relatório
                    </Box>
                }
                description={
                    <>
                        <span style={{ display: 'block', marginBottom: '8px', fontSize: '16px', fontWeight: '500' }}>
                            Você está prestes a mover o cliente para a tabela "Enviar Relatório". Confira as informações abaixo:
                        </span>
                        <span style={{ display: 'block', marginBottom: '8px' }}>
                            <strong>Nome:</strong>{' '}
                            <span style={{ color: '#1976d2', fontWeight: 'bold' }}>
                                {clienteSelecionado?.nome_cliente}
                            </span>
                        </span>
                        <span style={{ display: 'block', marginBottom: '8px' }}>
                            <strong>CPF/CNPJ:</strong>{' '}
                            <span style={{ color: '#d32f2f', fontWeight: 'bold' }}>
                                {clienteSelecionado?.cpf_cnpj}
                            </span>
                        </span>
                        <span style={{ fontStyle: 'italic', color: '#757575', marginTop: '16px', display: 'block' }}>
                            * Esta ação é irreversível.
                        </span>
                    </>
                }
                
                
                confirmButtonText="Mover"
                cancelButtonText="Cancelar"
            />

            {/* Dialog para exibir resultados do upload */}
            <CustomDialog
                open={uploadDialogOpen}
                onConfirm={handleCloseUploadDialog} // Atualizado
                onClose={handleCloseUploadDialog}
                title="Resultados do Upload"
                description={
                    <>
                        <span style={{ display: 'block', marginBottom: '8px', fontSize: '16px', fontWeight: '500' }}>
                            Resultados do processamento do arquivo:
                        </span>
                        <span style={{ display: 'block', marginBottom: '8px', color: 'green' }}>
                            <strong>Clientes Movidos com Sucesso:</strong> {uploadResults.success.length}
                        </span>
                        <span style={{ display: 'block', marginBottom: '8px', color: 'red' }}>
                            <strong>Erros/Clientes Não Encontrados:</strong> {uploadResults.error.length}
                        </span>
                        {uploadResults.error.length > 0 && (
                            <>
                                <span style={{ display: 'block', marginTop: '16px', fontWeight: '500' }}>
                                    <strong>Detalhes dos Erros:</strong>
                                </span>
                                {uploadResults.error.map((err, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            display: 'block',
                                            marginBottom: '4px',
                                            paddingLeft: '16px',
                                            fontSize: '14px',
                                            color: '#333',
                                        }}
                                    >
                                        - {err}
                                    </span>
                                ))}
                            </>
                        )}
                    </>
                }
                
                
                
                confirmButtonText="Fechar"
                cancelButtonText="Cancelar"
            />

            {/* CustomDialog para Instruções */}
            <CustomDialog
                open={instructionsDialogOpen}
                onClose={handleCloseInstructionsDialog}
                title="Instruções para o Arquivo de Upload"
                description={
                    <>
                        <span style={{ display: 'block', marginBottom: '12px', fontSize: '16px', fontWeight: 'bold' }}>
                            Para garantir o correto processamento do arquivo, siga as instruções abaixo:
                        </span>
                        <span style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                            <strong>1. Formatos Aceitos:</strong> O arquivo deve estar no formato <strong>.csv</strong>, <strong>.xlsx</strong>, ou <strong>.xls</strong>.
                        </span>
                        <span style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                            <strong>2. Coluna Obrigatória:</strong> Certifique-se de que o arquivo contém uma coluna chamada <strong>"CPF/CNPJ"</strong>.
                        </span>
                        <span style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                            <strong>3. Remover Duplicatas:</strong> Antes de enviar, remova duplicatas para evitar erros.
                        </span>
                        <span style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                            <strong>4. Validação:</strong> Verifique se todos os CPFs/CNPJs estão no formato válido.
                        </span>
                        <span style={{ display: 'block', marginTop: '16px', fontSize: '14px', fontStyle: 'italic', color: '#757575' }}>
                            <strong>Observação:</strong> Este upload é utilizado para mover clientes da tabela <strong>"Não Enviar Relatório"</strong> para a tabela <strong>"Enviar Relatório"</strong>.
                        </span>
                    </>
                }
                confirmButtonText="Entendi"
                onConfirm={handleCloseInstructionsDialog}
            />

            <CustomSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                title={snackbar.title}
                onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
                position={snackbar.position}
            />
        </Box>
    );
};

export default CarteiraNaoEnviar;
