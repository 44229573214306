import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, CircularProgress, IconButton, Tooltip, Checkbox, TextField , InputAdornment} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { fetchClientes, uploadFileGestaoCarteira, enviarEmailsGestaoCarteira, sendUpdateEmail } from '../../services/clientes';
import DataTable from '../../components/DataTable';
import CustomDialog from '../../components/Modal/CustomDialog';
import CustomSnackbar from '../../components/CustomSnackbar';
import EditButton from '../../components/ActionButton/EditButton'
import { Close as CloseIcon } from '@mui/icons-material';

const CarteiraRelatorio = () => {
    const [clientes, setClientes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedClientes, setSelectedClientes] = useState([]);
    const [dataReferencia, setDataReferencia] = useState('');
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [openInstructionsDialog, setOpenInstructionsDialog] = useState(false);
    // Estado único para o CustomSnackbar
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success', title: '' });
    const [reloadFlag, setReloadFlag] = useState(false);

    // Estado para edição de email
    const [editingCliente, setEditingCliente] = useState(null);
    const [editingEmail, setEditingEmail] = useState("");
    const [editingDialogOpen, setEditingDialogOpen] = useState(false);

    useEffect(() => {
        const loadClientes = async () => {
            try {
                const data = await fetchClientes();
                setClientes(data);
            } catch (error) {
                console.error("Erro ao carregar dados dos clientes:", error);
                setError("Erro ao carregar dados dos clientes. Tente novamente mais tarde.");
            } finally {
                setLoading(false);
            }
        };

        loadClientes();
    }, [reloadFlag]);

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setUploading(true);
        try {
          const response = await uploadFileGestaoCarteira(file); // Captura a resposta do servidor

          if (response.cpf_cnpj_invalidos && response.cpf_cnpj_invalidos.length > 0) {
              setSnackbar({
                  open: true,
                  message: `Arquivo processado, mas alguns registros foram ignorados: ${response.cpf_cnpj_invalidos.join(", ")}`,
                  severity: "warning",
                  title: "Atenção",
                  position: { vertical: 'top', horizontal: 'center' }
              });
          } else {
              setSnackbar({
                  open: true,
                  message: response.message || "Arquivo processado com sucesso, todos os registros válidos.",
                  severity: "success",
                  title: "Sucesso",
                  position: { vertical: 'top', horizontal: 'right' }
              });
          }
        // Atualiza o reloadFlag para recarregar os dados da tabela
        setReloadFlag((prev) => !prev);

        } catch (error) {
            console.error("Erro ao fazer upload do arquivo:", error);
            // alert(error.message);
            setSnackbar({
              open: true,
              message: error.message || "Erro ao fazer upload do arquivo.",
              severity: "error",
              title: "Erro",
              position: { vertical: 'top', horizontal: 'center' }
          });

        } finally {
            setUploading(false);
        }
    };

    const handleCheckboxChange = (cpfCnpj) => {
        setSelectedClientes((prev) =>
            prev.includes(cpfCnpj) ? prev.filter((id) => id !== cpfCnpj) : [...prev, cpfCnpj]
        );
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const allClientes = clientes.map(cliente => cliente.cpf_cnpj);
            setSelectedClientes(allClientes);
        } else {
            setSelectedClientes([]);
        }
    };

    const validateDataReferencia = () => {
      const regex = /^\d{4}-(0[1-9]|1[0-2])$/; // Verifica se está no formato YYYY-MM
      const currentDate = new Date();
      const [year, month] = dataReferencia.split("-").map(Number);
  
      // Verifica o formato de ano e mês (YYYY-MM)
      if (!regex.test(dataReferencia)) {
          setSnackbar({
              open: true,
              message: "Data inválida. Use o formato YYYY-MM.",
              severity: "error",
              title: "Erro",
              position: { vertical: 'top', horizontal: 'center' }
          });
          return false;
      }
  
      // Verifica se a data é maior que o ano e mês atuais
      if (year > currentDate.getFullYear() || (year === currentDate.getFullYear() && month > currentDate.getMonth() + 1)) {
          setSnackbar({
              open: true,
              message: "Data fora do intervalo permitido. Não pode ser maior que o mês atual.",
              severity: "error",
              title: "Erro",
              position: { vertical: 'top', horizontal: 'center' }
          });
          return false;
      }
  
      return true; // Se tudo estiver válido, retorna true
    };
  
    const handleGenerateReports = async () => {
      if (!validateDataReferencia()) return;

        try {
            await enviarEmailsGestaoCarteira(dataReferencia, selectedClientes);
            console.log(dataReferencia, selectedClientes)
            setSnackbar({
              open: true,
              message: "Relatórios enviados com sucesso!",
              severity: "success",
              title: "Sucesso",
              position: { vertical: 'top', horizontal: 'right' }
          });
        } catch (error) {
            console.error("Erro ao enviar relatórios:", error);
            // alert(error.message);
            setSnackbar({
              open: true,
              message: error.message || "Erro ao enviar relatórios.",
              severity: "error",
              title: "Erro",
              position: { vertical: 'top', horizontal: 'center' }
          });
        }
    };

    const handleOpenConfirmDialog = () => {
        if (dataReferencia && selectedClientes.length > 0) {
            setConfirmDialogOpen(true);
        }
    };

    const handleCloseConfirmDialog = () => {
        setConfirmDialogOpen(false);
    };
  
    const handleCloseSnackbar = () => {
        setSnackbar((prev) => ({ ...prev, open: false }));
    };

    const handleOpenInstructionsDialog = () => setOpenInstructionsDialog(true);
    const handleCloseInstructionsDialog = () => setOpenInstructionsDialog(false);


    // Funções de edição (Email)
    const handleOpenEditingDialog = (cliente) => {
        setEditingCliente(cliente);
        setEditingEmail(cliente.email); // Preenche o campo com o email atual
        setEditingDialogOpen(true);
    };

    const handleCloseEditingDialog = () => {
        setEditingCliente(null);
        setEditingEmail("");
        setEditingDialogOpen(false);
    };

    // const handleUpdateEmail = async () => {
    //     // Verifica se o campo está vazio ou contém apenas espaços em branco
    //     if (!editingEmail || editingEmail.trim().length === 0) {
    //         setSnackbar({
    //             open: true,
    //             message: "O campo de email não pode estar vazio.",
    //             severity: "error",
    //             title: "Erro",
    //         });
    //         return; // Impede a execução da função
    //     }

    //     // Verifica o formato do email
    //     const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    //     if (!emailRegex.test(editingEmail.trim())) {
    //         setSnackbar({
    //             open: true,
    //             message: "Insira um email válido.",
    //             severity: "error",
    //             title: "Erro",
    //         });
    //         return; // Impede a execução da função
    //     }

    //     try {
    //         await sendUpdateEmail(editingCliente.cpf_cnpj, editingEmail.trim()); // Chama o endpoint
    //         setClientes((prevClientes) =>
    //             prevClientes.map((cliente) =>
    //                 cliente.cpf_cnpj === editingCliente.cpf_cnpj
    //                     ? { ...cliente, email: editingEmail.trim() }
    //                     : cliente
    //             )
    //         ); // Atualiza o estado local com o novo email
    //         setSnackbar({
    //             open: true,
    //             message: "Email atualizado com sucesso!",
    //             severity: "success",
    //             title: "Sucesso",
    //             position: { vertical: 'top', horizontal: 'right' }
                
    //         });
    //         handleCloseEditingDialog(); // Fecha o diálogo
    //     } catch (error) {
    //         console.error("Erro ao atualizar o email:", error);
    //         setSnackbar({
    //             open: true,
    //             message: error.message || "Erro ao atualizar o email.",
    //             severity: "error",
    //             title: "Erro",
    //             position: { vertical: 'top', horizontal: 'center' }
    //         });
    //     }
    // };
    
    const handleUpdateEmail = async () => {
        const trimmedEmail = editingEmail.trim(); // Remove espaços extras
    
        // Verifica se o campo está vazio
        if (!trimmedEmail) {
            setSnackbar({
                open: true,
                message: "O campo de email não pode estar vazio.",
                severity: "error",
                title: "Erro",
            });
            return; // Impede a execução da função
        }
    
        // Verifica o formato do email
        const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
        if (!emailRegex.test(trimmedEmail)) {
            setSnackbar({
                open: true,
                message: "Insira um email válido.",
                severity: "error",
                title: "Erro",
            });
            return; // Impede a execução da função
        }
    
        try {
            await sendUpdateEmail(editingCliente.cpf_cnpj, trimmedEmail); // Chama o endpoint
            setClientes((prevClientes) =>
                prevClientes.map((cliente) =>
                    cliente.cpf_cnpj === editingCliente.cpf_cnpj
                        ? { ...cliente, email: trimmedEmail }
                        : cliente
                )
            ); // Atualiza o estado local com o novo email
            setSnackbar({
                open: true,
                message: "Email atualizado com sucesso!",
                severity: "success",
                title: "Sucesso",
                position: { vertical: 'top', horizontal: 'right' },
            });
            handleCloseEditingDialog(); // Fecha o diálogo
        } catch (error) {
            console.error("Erro ao atualizar o email:", error);
            setSnackbar({
                open: true,
                message: error.message || "Erro ao atualizar o email.",
                severity: "error",
                title: "Erro",
                position: { vertical: 'top', horizontal: 'center' },
            });
        }
    };
    

    const columns = [
      {
          field: 'select',
          headerName: 'Selecionar',
          headerAlign: 'center',
          sortable: false,
          flex:1,
          renderHeader: (params) => (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant="body2" sx={{ color: 'white', mr: 1 }}>
                      Selecionar
                  </Typography>
                  <Checkbox
                      indeterminate={selectedClientes.length > 0 && selectedClientes.length < clientes.length}
                      checked={selectedClientes.length === clientes.length}
                      onChange={handleSelectAll}
                      sx={{
                        color: 'white',
                        '&.Mui-checked': {
                            color: 'white',
                        },
                        '&.MuiCheckbox-indeterminate': {
                            color: 'white',  // Mantém a cor branca no estado indeterminate
                        },
                    }}
                  />
              </Box>
          ),
          renderCell: (params) => (
              <Checkbox
                  checked={selectedClientes.includes(params.row.cpf_cnpj)}
                  onChange={() => handleCheckboxChange(params.row.cpf_cnpj)}
              />
          ),
     
      },
      { field: 'nome_planejador', headerName: 'Nome do Planejador',headerAlign: 'center',flex: 1 },
      { field: 'cliente', headerName: 'Cliente',headerAlign: 'center', flex: 1 },
      { field: 'cpf_cnpj', headerName: 'CPF/CNPJ',headerAlign: 'center', flex: 1 },
      { field: 'email', headerName: 'Email',headerAlign: 'center', flex: 1 },
      {
        field: 'acoes',
        headerName: 'Ações',
        headerAlign: 'center',
        sortable: false,
        flex: 1,
        renderCell: (params) => (
            <EditButton onEdit={() => handleOpenEditingDialog(params.row)} nome="Editar Email" />
        ),
    },
  ];
  

    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom>Relatório de Carteira</Typography>
            
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
                <Button
                    variant="contained"
                    component="label"
                    startIcon={<UploadFileIcon />}
                >
                    Upload Arquivo (CSV ou XLSX)
                    <input
                        type="file"
                        accept=".csv, .xlsx"
                        hidden
                        onChange={handleFileUpload}
                    />
                </Button>

                <Tooltip title="Instruções para o arquivo">
                    <IconButton onClick={handleOpenInstructionsDialog}>
                        <InfoIcon color="secondary" />
                    </IconButton>
                </Tooltip>
            </Box>

            <Typography variant="h5" gutterBottom>Geração de Relatórios para Clientes</Typography>

            <TextField
                label="Data de Referência (ANO-MES)"
                placeholder="YYYY-MM"
                value={dataReferencia}
                onChange={(e) => {
                    let value = e.target.value;

                    // Remove caracteres não permitidos e limita a 7 caracteres
                    value = value.replace(/[^0-9-]/g, '').slice(0, 7);

                    setDataReferencia(value);
                }}
                slotProps={{
                    input: {
                        maxLength: 7,
                        // Adiciona o ícone de limpar quando houver conteúdo no campo
                        endAdornment: (
                        <InputAdornment position="end">
                            {dataReferencia && (
                                <IconButton onClick={() => setDataReferencia('')}>
                                    <CloseIcon sx={{ color:'red' }} /> {/* Ícone usa a cor de erro do tema */}
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                    }
        
                }}

                onKeyDown={(e) => {
                    // Permite apenas números, backspace, delete e "-"
                    if (!/[0-9]|Backspace|Delete|-/.test(e.key)) {
                        e.preventDefault();
                    }
                }}
                sx={{ mb: 3 }}
                fullWidth
            />



            {uploading && <CircularProgress sx={{ display: 'block', mb: 2 }} />}

            {error && <Typography color="error">{error}</Typography>}

            <DataTable
                columns={columns}
                rows={clientes}
                idColumn="cpf_cnpj"
                loading={loading}
            />

            <Button
                variant="contained"
                color="primary"
                onClick={handleOpenConfirmDialog}
                disabled={!dataReferencia || selectedClientes.length === 0}
                sx={{ mt: 3 }}
            >
                Gerar Relatórios
            </Button>
            
            <CustomDialog
                open={confirmDialogOpen}
                onClose={handleCloseConfirmDialog}
                title="Confirmar Geração de Relatórios"
                description="Tem certeza de que deseja gerar e enviar relatórios para os clientes selecionados?"
                confirmButtonText="Confirmar"
                cancelButtonText="Cancelar"
                onConfirm={() => {
                    handleGenerateReports();
                    handleCloseConfirmDialog();
                }}
            />

            <CustomDialog
              open={openInstructionsDialog}
              onClose={handleCloseInstructionsDialog}
              title="Instruções para o Arquivo CSV ou XLSX"
              description={
                <>
                    <span style={{ display: 'block', lineHeight: 1.6, color: '#333', fontSize: '1rem', fontWeight: 500 }}>
                        O arquivo deve ser nomeado como: <strong>ANO.MES_Relatório.Cliente</strong>
                    </span>
                    <span style={{ display: 'block', marginTop: '1rem', fontSize: '1rem', fontWeight: 500 }}>
                        O arquivo deve conter as seguintes colunas esperadas:
                    </span>
                    <span style={{ display: 'block', marginTop: '0.5rem', paddingLeft: '1rem', fontSize: '1rem', color: '#333' }}>
                        - <strong>Planej</strong> (String)
                    </span>
                    <span style={{ display: 'block', paddingLeft: '1rem', fontSize: '1rem', color: '#333' }}>
                        - <strong>Nome Planej</strong> (String)
                    </span>
                    <span style={{ display: 'block', paddingLeft: '1rem', fontSize: '1rem', color: '#333' }}>
                        - <strong>CPF/CNPJ</strong> (String)
                    </span>
                    <span style={{ display: 'block', paddingLeft: '1rem', fontSize: '1rem', color: '#333' }}>
                        - <strong>Corret</strong> (String)
                    </span>
                    <span style={{ display: 'block', paddingLeft: '1rem', fontSize: '1rem', color: '#333' }}>
                        - <strong>Cliente</strong> (String)
                    </span>
                    <span style={{ display: 'block', paddingLeft: '1rem', fontSize: '1rem', color: '#333' }}>
                        - <strong>Código</strong> (Inteiro)
                    </span>
                    <span style={{ display: 'block', paddingLeft: '1rem', fontSize: '1rem', color: '#333' }}>
                        - <strong>PL Final</strong> (Numérico)
                    </span>
                    <span style={{ display: 'block', paddingLeft: '1rem', fontSize: '1rem', color: '#333' }}>
                        - <strong>N° Dia Úteis</strong> (Numérico)
                    </span>
                    <span style={{ display: 'block', paddingLeft: '1rem', fontSize: '1rem', color: '#333' }}>
                        - <strong>Pfee</strong> (Numérico)
                    </span>
                    <span style={{ display: 'block', paddingLeft: '1rem', fontSize: '1rem', color: '#333' }}>
                        - <strong>Gestão Mín. Mont</strong> (Numérico)
                    </span>
                    <span style={{ display: 'block', paddingLeft: '1rem', fontSize: '1rem', color: '#333' }}>
                        - <strong>Cash Back Conta</strong> (Numérico)
                    </span>
                    <span style={{ display: 'block', paddingLeft: '1rem', fontSize: '1rem', color: '#333' }}>
                        - <strong>Cobrança em Conta</strong> (Numérico)
                    </span>
                    <span style={{ display: 'block', paddingLeft: '1rem', fontSize: '1rem', color: '#333' }}>
                        - <strong>E-mail</strong> (String)
                    </span>
                    <span style={{ display: 'block', marginTop: '1rem', fontSize: '1rem', fontWeight: 500 }}>
                        As colunas indicadas como <strong>numéricas</strong> devem conter apenas números.
                    </span>
                    <span style={{ display: 'block', fontSize: '1rem', color: '#d32f2f', fontWeight: 500, marginTop: '1rem' }}>
                        <strong>Obs:</strong> arquivo .csv somente os delimitados por <strong>;</strong>.
                    </span>
                </>
            }
            
              confirmButtonText="Entendi"
              onConfirm={handleCloseInstructionsDialog}
            />
            <CustomDialog
                open={editingDialogOpen}
                onClose={handleCloseEditingDialog}
                onConfirm={handleUpdateEmail}
                title="Editar Email do Cliente"
                fields={[
                    {
                        name: "email",
                        label: "Novo Email",
                        type: "text",
                        value: editingEmail,
                        placeholder: "Insira o novo email",
                    },
                ]}
                onFieldChange={(name, value) => {
                    if (name === "email") setEditingEmail(value.trim()); // Remove espaços extras
                }}
            />

            <CustomSnackbar
                open={snackbar.open}
                onClose={handleCloseSnackbar}
                message={snackbar.message}
                severity={snackbar.severity}
                title={snackbar.title}
                position={snackbar.position}
            />
        </Box>
    );
};

export default CarteiraRelatorio;

