import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useTheme } from '@mui/material/styles';

const DownloadButton = ({ onDownload, nome }) => {
  const theme = useTheme();

  return (
    <Tooltip title="Download" arrow>
      <IconButton
        sx={{
          color: theme.palette.primary.light,
        }}
        aria-label="download"
        onClick={() => onDownload(nome)}
      >
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  );
};

export default DownloadButton;
