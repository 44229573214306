import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const DataTable = ({ columns, rows, idColumn, loading, pageSizeOptions = [5, 10, 25, 50, 100], onCellClick }) => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);

  const formattedRows = rows.map((row, index) => ({
    id: row[idColumn] || index + 1,
    ...row,
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxHeight: 650, // Limite máximo da altura da tabela
        minHeight: 100, // Altura mínima para a tabela
      }}
    >
      <DataGrid
        rows={formattedRows}
        columns={columns}
        pagination
        page={page}
        pageSize={pageSize}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pageSizeOptions={pageSizeOptions}
        loading={loading}
        {...(onCellClick && { onCellClick })}
        sx={{
          flexGrow: 1, // Faz com que o DataGrid ocupe o espaço disponível no contêiner flex
          '& .MuiDataGrid-columnHeader': {      
            backgroundColor: theme.palette.primary.main, // Aplica a cor primária ao cabeçalho
            color: theme.palette.common.white, // Cor branca para o texto no cabeçalho
            fontWeight: 'bold',
            borderBottom: `2px solid ${theme.palette.divider}`, // Borda inferior para separar as colunas
            
          },
          '& .MuiDataGrid-virtualScroller': {
            overflowY: 'auto', // Habilita o scroll apenas para as linhas
            maxHeight: 'calc(100% - 100px)', // Ajusta a altura para header e footer fixos
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.paper,
          },
          '& .MuiDataGrid-cell': {
            borderBottom: `2px solid ${theme.palette.divider}`,
            borderRight: `2px solid ${theme.palette.divider}`,
            color: theme.palette.text.primary,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            cursor: 'pointer',
          },
          '& .MuiDataGrid-cell:last-child': {
            borderRight: 'none',
          },
          '& .MuiDataGrid-root': {
            backgroundColor: theme.palette.background.default,
          },
        }}
      />
    </Box>
  );
};

export default DataTable;

