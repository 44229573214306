import React from 'react';
import { Snackbar, Alert, AlertTitle } from '@mui/material';

const CustomSnackbar = ({
    open, 
    message,
    severity,
    onClose, 
    duration = 6000 , 
    title,
    position = { vertical: 'bottom', horizontal: 'center' } 
}) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={duration}
            onClose={onClose}
            
            anchorOrigin={position}
        >
            <Alert onClose={onClose} severity={severity} variant="filled" sx={{ width: '100%' } }>
                {title && <AlertTitle>{title}</AlertTitle>}
                {message}
            </Alert>
        </Snackbar>
    );
};

export default CustomSnackbar;
