import { apiWithAuth } from './api';  // Importa as instâncias de API com e sem autenticação


export const fetchClientes = async () => {
    try {
        const response = await apiWithAuth.get("/clientes")
        return response.data;
    } catch (error) {
        console.error("Erro ao obter dados dos clientes",error);
        throw new Error("Erro ao obter dados dos clientes. Tente novamente mais tarde.")
    }
};

export const uploadFileGestaoCarteira = async (file) => {
    try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await apiWithAuth.post("/clientes/gestao_carteira/upload_file", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });

        return response.data; // Retorna os dados de sucesso do servidor
    } catch (error) {
        // Verifica se o erro é de uma resposta do servidor
        if (error.response) {
            const status = error.response.status;
            
            switch (status) {
                case 400:
                    throw new Error("Erro ao processar o arquivo: Formato de arquivo não suportado ou dados inválidos.");
                case 401:
                    throw new Error("Erro de autenticação: Você precisa estar logado para realizar esta ação.");
                case 403:
                    throw new Error("Erro de permissão: Você não tem as permissões necessárias para fazer upload de arquivos.");
                case 500:
                    throw new Error("Erro interno do servidor: Ocorreu um problema ao inserir dados no banco de dados.");
                default:
                    throw new Error("Erro inesperado: Por favor, tente novamente mais tarde.");
            }
        } else {
            // Erro de rede ou erro desconhecido
            console.error("Erro de rede ou desconhecido:", error);
            throw new Error("Erro de rede: Não foi possível conectar ao servidor. Verifique sua conexão e tente novamente.");
        }
    }
};

export const enviarEmailsGestaoCarteira = async (dataReferencia, cpfsSelecionados) => {
    try {
        const response = await apiWithAuth.post(
            `/clientes/gestao_carteira/enviar_emails?data_referencia=${dataReferencia}`,
            cpfsSelecionados // Envia diretamente a lista de CPF/CNPJ como corpo da requisição
        );

        return response.data; // Retorna os dados de sucesso do servidor

    } catch (error) {
        if (error.response) {
            const status = error.response.status;
            const detail = error.response.data?.detail;

            if (status === 400) {
                // Tratamento específico para erro 400 com detalhes adicionais
                if (typeof detail === 'object' && detail.nao_existentes) {
                    throw new Error(
                        `Erro de requisição: Os seguintes CPF/CNPJ não foram encontrados:\n${detail.nao_existentes.map(cpf => `- ${cpf}`).join('\n')}`
                    );
                }
                throw new Error("Erro de requisição: Dados inválidos ou CPF/CNPJ não encontrados.");
            } else {
                // Para outros erros, retorna diretamente `error.message`
                throw new Error(error.message || "Erro inesperado: Por favor, tente novamente mais tarde.");
            }
        } else {
            // Erro de rede ou erro desconhecido
            console.error("Erro de rede ou desconhecido:", error);
            throw new Error("Erro de rede: Não foi possível conectar ao servidor. Verifique sua conexão e tente novamente.");
        }
    }
};



export const uploadFileComentarioGestor = async (file) => {
    try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await apiWithAuth.post("/clientes/comentario-gestor/upload_file", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });

        return response.data; // Retorna os dados de sucesso do servidor
    } catch (error) {
        console.error("Erro ao enviar arquivo", error);
        throw new Error(error.response?.data?.detail || error.message);
    }
};

export const fetchComentariosGestor = async () => {
    try {
        const response = await apiWithAuth.get("/clientes/comentario-gestor/listar_comentarios")
        return response.data;
    } catch (error) {
        console.error("Erro ao obter os comentários do gestor",error);
        throw new Error(error.message)
    }
};


export const downloadComentarioGestor = async (nome_arquivo) => {
    try {
        const response = await apiWithAuth.get(`/clientes/comentario-gestor/download/${nome_arquivo}`, {
            responseType: 'blob' // Define a resposta como blob para manipular o arquivo de download
        });

        // Retorna o blob do arquivo para ser manipulado pelo componente
        return response.data;

    } catch (error) {
        console.error('Erro ao baixar o comentário do gestor:', error);

        let errorMessage = 'Erro ao baixar o comentário do gestor. Por favor, tente novamente.';

        if (error.response) {
            const { status, data: errorData } = error.response;

            let errorDetails = errorData;
            if (errorData instanceof Blob) {
                try {
                    const text = await errorData.text();
                    errorDetails = JSON.parse(text); // Converte para JSON se a resposta for um blob JSON
                } catch (parseError) {
                    console.error('Erro ao processar a resposta de erro:', parseError);
                }
            }

            // Define mensagens específicas para os diferentes status HTTP
            switch (status) {
                case 400:
                    errorMessage = errorDetails?.detail || "Requisição inválida. Verifique o nome do arquivo fornecido.";
                    break;

                case 404:
                    if (errorDetails?.detail === "Arquivo não encontrado") {
                        errorMessage = "O arquivo especificado não foi encontrado.";
                    } else {
                        errorMessage = "Arquivo ou dados não encontrados para o nome fornecido.";
                    }
                    break;

                case 401:
                    errorMessage = "Acesso não autorizado. Verifique suas credenciais.";
                    break;

                case 403:
                    errorMessage = "Permissão negada. Verifique se você possui as permissões necessárias.";
                    break;

                case 500:
                    errorMessage = "Erro interno no servidor. Tente novamente mais tarde.";
                    break;

                default:
                    errorMessage = "Erro desconhecido. Por favor, tente novamente ou contate o suporte.";
            }
        }

        throw new Error(errorMessage);
    }
};


export const deleteComentarioGestor = async (nome_arquivo) => {
    try {
        const response = await apiWithAuth.delete(`/clientes/comentario-gestor/remover/${nome_arquivo}`)
        return response.data
    } catch (error) {
        console.error("Erro ao deletar o comentário do gestor", error);
        throw new Error(error.response?.data?.detail || error.message)
    }
};


export const fetchNaoEnviar = async () => {
    try {
        const response = await apiWithAuth.get("/clientes/gestao_carteira/nao_enviar")
        return response.data;
    } catch (error) {
        console.error("Erro ao obter os dados",error);
        throw new Error(error.message)
    }
};


export const fetchLogsEnviados = async (data_referencia) => {
    try {
        const response = await apiWithAuth.get(`/clientes/gestao_carteira/logs/enviados?data_referencia=${data_referencia}`)
        return response.data.logs_enviados;
    } catch (error) {
        console.error("Erro ao obter os dados",error);
        throw new Error(error.message)
    }
};

export const fetchNaoLogsEnviados = async (data_referencia) => {
    try {
        const response = await apiWithAuth.get(`/clientes/gestao_carteira/logs/nao_enviados?data_referencia=${data_referencia}`)
        return response.data.logs_nao_enviados;
    } catch (error) {
        console.error("Erro ao obter os dados",error);
        throw new Error(error.message)
    }
};

export const fetchDatasDisponiveisLogs = async () => {
    try {
        const response = await apiWithAuth.get(`/clientes/gestao_carteira/logs/datas_disponiveis`)
        return response.data.datas_disponiveis;
    } catch (error) {
        console.error("Erro ao obter os dados",error);
        throw new Error(error.message)
    }
};
// ------- Ainda não implementei -------------------------------------//
export const downloadRelatorioLogs = async (data_referencia) => {
    try {
        const response = await apiWithAuth.get(`/clientes/gestao_carteira/logs/download`, {
            params: { data_referencia },
            responseType: 'blob'  // Define a resposta como blob para manipular o arquivo de download
        });

        return response.data;
    } catch (error) {
        console.error("Erro ao baixar o relatório de logs", error);

        let errorMessage = 'Erro ao baixar o relatório de logs. Por favor, tente novamente.';

        if (error.response && error.response.data) {
            const { status} = error.response;
            if (status === 404) {
                errorMessage = "Nenhum log de enviados encontrado para a data informada.";
            } else if (status === 400) {
                errorMessage = "Formato de data inválido. Use o formato YYYY-MM.";
            } else {
                errorMessage = "Erro interno do servidor. Tente novamente mais tarde.";
            }
        }

        throw new Error(errorMessage);
    }
};
// ------- Ainda não implementei -------------------------------------//

export const gerarRelatorioPeriodico = async (data) => {
    try {
        const response = await apiWithAuth.post('/clientes/gestao_carteira/relatorio/download', data, {
            responseType: 'blob' // Define o tipo de resposta como blob para manipular o arquivo de download
        });

        // Retorna o blob do relatório se a requisição foi bem-sucedida
        return response.data;

    } catch (error) {
        console.error('Erro ao gerar o relatório:', error);

        let errorMessage = 'Erro ao gerar o relatório. Por favor, tente novamente.';

        if (error.response) {
            const { status, data: errorData } = error.response;

            let errorDetails = errorData;
            if (errorData instanceof Blob) {
                try {
                    const text = await errorData.text();
                    errorDetails = JSON.parse(text); // Converte para JSON
                } catch (parseError) {
                    console.error('Erro ao processar a resposta de erro:', parseError);
                }
            }

            // Definindo mensagens específicas para os diferentes status HTTP
            switch (status) {
                case 400:
                    if (errorDetails?.detail && typeof errorDetails.detail === 'object') {
                        const naoExistentes = errorDetails.detail.nao_existentes || [];
                        const listaNaoExistentes = naoExistentes.map(cpfCnpj => `- ${cpfCnpj}`).join('\n');
                        errorMessage = `${errorDetails.detail.message}\n\n${listaNaoExistentes}`;
                    } else {
                        errorMessage = errorDetails?.detail || "Requisição inválida. Verifique os dados fornecidos.";
                    }
                    break;

                case 404:
                    // Diferencia os tipos de erro 404 baseando-se no conteúdo de `detail`
                    if (errorDetails?.detail === "Arquivo do Comentário do gestor não encontrado.") {
                        errorMessage = "O arquivo de comentário do gestor para a data especificada não foi encontrado.";
                    } else if (errorDetails?.detail?.includes("Nenhum CPF/CNPJ possui registros")) {
                        errorMessage = errorDetails.detail;
                    } else {
                        errorMessage = "Dados ou registros não encontrados para o período ou cliente especificado.";
                    }
                    break;

                case 401:
                    errorMessage = "Acesso não autorizado. Verifique suas credenciais.";
                    break;

                case 403:
                    errorMessage = "Permissão negada. Verifique se você possui as permissões necessárias.";
                    break;

                case 500:
                    errorMessage = "Erro interno no servidor. Tente novamente mais tarde.";
                    break;

                default:
                    errorMessage = "Erro desconhecido. Por favor, tente novamente ou contate o suporte.";
            }
        }

        throw new Error(errorMessage);
    }
};


export const sendMoverClienteNaoEnviar = async (cpf_cnpj) => {
    try {
        const response = await apiWithAuth.post(`/clientes/gestao_carteira/mover_cliente?cpf_cnpj=${cpf_cnpj}`)
        return response.data;
    } catch (error) {
        console.error("Erro ao enviar os dados",error);
        throw new Error(error.message)
    }
};

export const uploadFileMoverClienteNaoEnviar = async (file) => {

    try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await apiWithAuth.post("/clientes/gestao_carteira/mover_clientes_upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });

        return response.data;
    } catch (error) {
        if (error.response) {
            console.error("Erro do servidor:", error.response.data);
            throw new Error(error.response.data.detail || "Erro ao processar o arquivo.");
        } else {
            console.error("Erro de conexão:", error.message);
            throw new Error("Erro de conexão com o servidor. Tente novamente mais tarde.");
        }
    }
};


export const sendUpdateEmail = async (cpf_cnpj, email) => {
    try {
        const response = await apiWithAuth.patch(`/clientes/email/update`, {
            cpf_cnpj,
            email
        });

        return response.data;
    } catch (error) {
        console.error("Erro ao atualizar o email:", error);
        throw new Error(error.response?.data?.detail || error.message);
    }
};
