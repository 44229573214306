import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // Ícone de menu para abrir o Sidebar
import Navbar from './NavBar'; // Componente da Navbar
import Logo from '../assets/img/Logo_Mont_icon.png'; // Importe o arquivo PNG
import DarkModeSwitch from './DarkModeSwitch'; // Importa o switch de Dark Mode
import LogoutButton from './LogoutButton'; // Importa o botão de logout
import Sidebar from './Sidebar'; // Importa o Sidebar

const Header = ({ darkMode, toggleDarkMode }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Estado para abrir e fechar o Sidebar
  const theme = useTheme(); // Obtenha o tema para utilizar as cores dinâmicas

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen); // Abre ou fecha o Drawer
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          paddingY: 1,
          backgroundColor: theme.palette.primary.dark, // Usar cor primária do tema
          boxShadow: theme.shadows[4], // Utiliza a sombra padrão do tema
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingX: { xs: 1, sm: 2 }, // Ajusta padding conforme o tamanho da tela
          }}
        >
          {/* Ícone do Sidebar sempre visível */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>

          {/* Logo e nome da dashboard */}
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <img
              src={Logo}
              alt="Logo Mont Asset"
              style={{
                height: '40px',
                filter: 'invert(1)', // Inverte as cores no modo escuro
                transition: 'filter 0.3s ease',
                marginRight: '10px',
              }}
            />
            <Typography
              variant="h6"
              sx={{
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                color: theme.palette.primary.contrastText, // Cor de contraste para o texto
              }}
            >
              WealthSystem | Mont Asset
            </Typography>
          </Box>

          {/* Navbar, Switch e Logout só visíveis em telas grandes */}
          <Box
            sx={{
              display: { xs: 'none', lg: 'flex' }, // Mostra a partir de 'md' (900px) para garantir que apareça em telas intermediárias
              flexGrow: 1,
              justifyContent: 'center',
              alignItems: 'center', // Centraliza verticalmente os itens
              paddingX: { md: 2, lg: 4 },
              width: '100%',
              transition: 'all 0.3s ease-in-out', // Adiciona uma transição suave para mudanças de tamanho e posição
              margin: '0 auto', // Centraliza horizontalmente o Box no container pai
            }}
          >
            <Navbar />
          </Box>

          {/* Dark Mode Switch e Logout só visíveis em telas grandes */}
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' }, // Esconde em telas menores que 'lg'
              alignItems: 'center',
              gap: 1, // Ajustado para reduzir o espaçamento
            }}
          >
            <DarkModeSwitch darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
            <LogoutButton />
          </Box>
        </Toolbar>
      </AppBar>

      {/* Sidebar como Drawer temporário */}
      <Sidebar 
        isDrawerOpen={isDrawerOpen} 
        toggleDrawer={toggleDrawer} 
        darkMode={darkMode} 
        toggleDarkMode={toggleDarkMode} 
      />
    </>
  );
};

export default Header;
