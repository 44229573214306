import React from 'react';
import { Card, CardContent, Typography, Box, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ModuleCard = ({ title, description, icon, route }) => {
  const navigate = useNavigate();
  const theme = useTheme(); // Usar cores do tema

  return (
    <Card
      onClick={() => navigate(route)}
      sx={{
        cursor: 'pointer',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxShadow: theme.shadows[3],
        '&:hover': {
          transform: 'scale(1.02)',
          boxShadow: theme.shadows[6],
        },
        backgroundColor: theme.palette.background.paper, // Usar o background do tema
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          {/* Aplica a cor dinâmica a todos os ícones que forem passados */}
          {React.cloneElement(icon, {
            sx: {
              ...icon.props.sx,
              color: theme.palette.primary.main, // Aplica a cor do tema
            },
          })}
        </Box>
        <Typography variant="h6" align="center" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" align="center" color={theme.palette.text.secondary}>
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ModuleCard;
