import React, { createContext, useState, useContext } from 'react';

// Cria o contexto para o tema (dark mode / light mode)
const ThemeContext = createContext();

export const useThemeContext = () => useContext(ThemeContext);

// Provedor do contexto para encapsular os componentes
export const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);  // Estado para dark mode

  // Alterna entre os modos claro e escuro
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};
