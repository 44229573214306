// Função de login que chama a API e retorna os tokens
import { apiWithoutAuth ,apiWithAuth } from './api';  // Importa as instâncias de API com e sem autenticação
import qs from 'qs';  // Importa a biblioteca para formatar os dados no estilo x-www-form-urlencoded


  
// Função de login que chama a API e retorna os tokens
export const login = async (username, password) => {
  try {
    const response = await apiWithoutAuth.post(
      '/auth/token',
      qs.stringify({ username, password }),  // Formata os dados para x-www-form-urlencoded
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    return response.data;  // Retorna o access_token e refresh_token
  } catch (error) {
    // Identifica o tipo de erro e lança uma mensagem específica
    if (error.response?.status === 401) {
      throw new Error('Incorrect username or password');
    } else if (error.response?.status === 403) {
      throw new Error('User is disabled.');
    } else {
      throw new Error('Server is currently unavailable. Please try again later.');
    }
  }
};

// Função de logout que recebe a instância da API autenticada
export const logout = async (api) => {
  try {
    const response = await api.post('/auth/logout');  // Faz a requisição de logout
  //   sessionStorage.removeItem('access_token');  // Remove o access_token
  //   sessionStorage.removeItem('refresh_token');  // Remove o refresh_token
    return response.data;
  } catch (error) {
    console.error('Erro durante o logout:', error);
    throw error;
  }
};


// Função para verificar se o usuário tem escopo de admin
export const checkAdminScope = async () => {
try {
  const response = await apiWithAuth.get('/auth/is-admin'); // Chama o endpoint de verificação de admin
  return response.data.is_admin; // Retorna true se for admin, caso contrário false
} catch (error) {
  console.error('Erro ao verificar escopo de admin:', error);
  throw new Error('Erro ao verificar permissões. Tente novamente mais tarde.');
}
};


// Função para verificar se o usuário tem escopo de admin
export const fetchUsers= async () => {
  try {
    const response = await apiWithAuth.get('/auth/users/'); // Chama o endpoint de verificação de admin
    return response.data; // Retorna true se for admin, caso contrário false
  } catch (error) {
    console.error('Erro ao obter usuários:', error);
    throw new Error('Erro ao obter usuários. Tente novamente mais tarde.');
  }
};

export const createUsers= async (data) => {
  try {
    const response = await apiWithAuth.post('/auth/users/create',data); // Chama o endpoint de verificação de admin
    return response.data; // Retorna true se for admin, caso contrário false
  } catch (error) {
    console.error('Erro ao criar usuário:', error);
    throw new Error('Erro ao criar usuário. Tente novamente mais tarde.');
  }
};

// Atualizar
export const updateUser = async (username, updateData) => {
  try {
    const response = await apiWithAuth.patch(`/auth/users/${username}`, updateData);
    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar usuário :', error);
    throw error;
  }
};