export function downloadFile(blob, fileName) {
    const url = window.URL.createObjectURL(blob); // Cria uma URL temporária para o Blob
    const a = document.createElement('a'); // Cria um link <a>
    a.style.display = 'none'; // Oculta o link
    a.href = url;
    a.download = fileName; // Define o nome do arquivo a ser baixado
    document.body.appendChild(a); // Adiciona o link à página
    a.click(); // Simula o clique no link para iniciar o download
    window.URL.revokeObjectURL(url); // Remove a URL temporária para liberar memória
    document.body.removeChild(a); // Remove o link da página
}
