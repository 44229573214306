// components/LoadingIndicator.jsx
import React from 'react';
import { CircularProgress, Box, Typography, useTheme } from '@mui/material';

const LoadingIndicator = ({ message = 'Carregando...', size = 40 }) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100px"
      bgcolor={theme.palette.background.default}
      p={2}
      borderRadius="8px"
    >
      <CircularProgress size={size} />
      <Typography
        variant="body2"
        color={theme.palette.text.secondary}
        mt={2}
      >
        {message}
      </Typography>
    </Box>
  );
};

export default LoadingIndicator;
