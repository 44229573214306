// components/admin/AdminHeader.jsx
import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AdminNavBar from './AdminNavBar';
import Logo from '../../assets/img/Logo_Mont_icon.png';
import DarkModeSwitch from '../DarkModeSwitch';
import LogoutButton from '../LogoutButton';
import AdminSidebar from './AdminSidebar';

const AdminHeader = ({ darkMode, toggleDarkMode }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          paddingY: 1,
          backgroundColor: theme.palette.primary.dark,
          boxShadow: theme.shadows[4],
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingX: { xs: 1, sm: 2 },
          }}
        >
          {/* Ícone do Sidebar */}
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>

          {/* Logo e nome do painel de administração */}
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <img
              src={Logo}
              alt="Logo Mont Asset"
              style={{
                height: '40px',
                filter: 'invert(1)',
                transition: 'filter 0.3s ease',
                marginRight: '10px',
              }}
            />
            <Typography
              variant="h6"
              sx={{
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                color: theme.palette.primary.contrastText,
              }}
            >
              Admin Panel | Mont Asset
            </Typography>
          </Box>

          {/* AdminNavBar visível em telas grandes */}
          <Box
            sx={{
              display: { xs: 'none', lg: 'flex' },
              flexGrow: 1,
              justifyContent: 'center',
              paddingX: { md: 2, lg: 4 },
              transition: 'all 0.3s ease-in-out',
              margin: '0 auto',
            }}
          >
            <AdminNavBar />
          </Box>

          {/* Dark Mode Switch e Logout */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', gap: 1 }}>
            <DarkModeSwitch darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
            <LogoutButton />
          </Box>
        </Toolbar>
      </AppBar>

      {/* AdminSidebar como Drawer temporário */}
      <AdminSidebar
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        darkMode={darkMode}
        toggleDarkMode={toggleDarkMode}
      />
    </>
  );
};

export default AdminHeader;
