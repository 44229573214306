import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Footer = () => {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3),
        marginTop: 'auto',
        boxShadow: theme.palette.mode === 'light'
          ? '0 -1px 5px rgba(0, 0, 0, 0.1)'
          : '0 -1px 5px rgba(255, 255, 255, 0.1)',
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
        >
          © {new Date().getFullYear()} Mont Asset. Todos os direitos reservados.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
