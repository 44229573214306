// routes.js
export const menuItems = [
    { 
      text: 'Home', 
      route: '/home',
    },
    { 
      text: 'Planejadores', 
      route: '/planejadores',
    },
    {
      text: "Report",
      route: "/report"
    },
    { 
      text: 'Fundos de Investimento', 
      route: '/fundos-investimentos',
      submodules: [
        { text: 'Ranking Fundos', route: '/fundos-investimentos/ranking_fund' },
        { text: 'Ranking Previdência', route: '/fundos-investimentos/ranking_prev' },
        { text: 'Parâmetros', route: '/fundos-investimentos/parametros' },
        { text: 'Fundos Atenção', route: '/fundos-investimentos/fundos_atencao' },
        { text: 'Buscar Fundos', route: '/fundos-investimentos/busca_fundos' },
        { text: 'Relatório de Risco', route: '/fundos-investimentos/relatorio_risco' },
      ]
    },
    {
      text: 'Renda Variável',
      route: '/renda-variavel',
      submodules: [
        { text: "Classificação", route:'/renda-variavel/classificacao'},
        { text: "Configurações", route:'/renda-variavel/configuracoes' },
        { text: "Fundos na Bolsa", route:'/renda-variavel/fundos_bolsa' },
        { text: "ETF", route:'/renda-variavel/etf' },
        { text: "Ações e BDRs", route:'/renda-variavel/acoes_bdrs' },
        { text: "Proventos", route:'/renda-variavel/proventos' },
      ]},
    { text: 'Crédito Privado',
      route: '/credito_privado',
      submodules: [
        { text: 'Ranking', route: '/credito_privado/ranking' },
        { text: "Configurações", route: '/credito_privado/configuracoes' },
      ]
    },
  ];
  