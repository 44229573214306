// components/admin/AdminNavBar.jsx
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { adminMenuItems } from '../../routes/adminRoutes';

const AdminNavBar = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', gap: 4 }}>
      {adminMenuItems.map((item, index) => (
        <Typography
          key={index}
          onClick={() => navigate(item.route)}
          sx={{
            cursor: 'pointer',
            padding: '6px 0',
            '&:hover': { color: 'primary.light' },
            transition: 'color 0.2s',
          }}
        >
          {item.text}
        </Typography>
      ))}
    </Box>
  );
};

export default AdminNavBar;
