import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { fetchDatasDisponiveisLogs, fetchLogsEnviados, fetchNaoLogsEnviados, downloadRelatorioLogs } from '../../services/clientes';
import DataTable from '../../components/DataTable';
import LoadingIndicator from '../../components/LoadingIndicator';
import CustomSnackbar from '../../components/CustomSnackbar';
import CustomDialog from '../../components/Modal/CustomDialog';
import { downloadFile } from '../../components/utils/downloadUtils';
import FullScreenLoader from '../../components/FullScreenLoader'; 

const CarteiraLogs = () => {
    const [datasDisponiveis, setDatasDisponiveis] = useState([]);
    const [dataReferencia, setDataReferencia] = useState('');
    const [loadingLogs, setLoadingLogs] = useState(false);
    const [logsEnviados, setLogsEnviados] = useState([]);
    const [logsNaoEnviados, setLogsNaoEnviados] = useState([]);
    const [mostrarEnviados, setMostrarEnviados] = useState(true);
    const [showTable, setShowTable] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success', title: '' });
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Estado para o FullScreenLoader

    useEffect(() => {
      const loadDatasDisponiveis = async () => {
          try {
              const datas = await fetchDatasDisponiveisLogs();
              setDatasDisponiveis(datas);
          } catch (error) {
              setSnackbar({
                  open: true,
                  message: "Erro ao carregar as datas disponíveis. Tente novamente mais tarde.",
                  severity: "error",
                  title: "Erro",
                  position: { vertical: 'top', horizontal: 'center' }
              });
          }
      };
  
      loadDatasDisponiveis();
  }, []);
  

    const handleDataChange = (event) => {
        setDataReferencia(event.target.value);
    };

    const fetchLogs = async () => {
        if (!dataReferencia) return;
        setLoadingLogs(true);
        setShowTable(true);

        try {
            if (mostrarEnviados) {
                const enviados = await fetchLogsEnviados(dataReferencia);
                setLogsEnviados(enviados);
            } else {
                const naoEnviados = await fetchNaoLogsEnviados(dataReferencia);
                setLogsNaoEnviados(naoEnviados);
            }
        } catch (error) {
            setSnackbar({
                open: true,
                message: "Erro ao carregar os logs. Tente novamente mais tarde.",
                severity: "error",
                title: "Erro",
                position: { vertical: 'top', horizontal: 'center' }
            });
        } finally {
            setLoadingLogs(false);
        }
    };

    const handleDownloadClick = () => {
        setConfirmDialogOpen(true); // Abre o diálogo de confirmação
    };

    const handleConfirmDownload = async () => {
      setIsLoading(true); // Exibe o FullScreenLoader
        try {
            const blob = await downloadRelatorioLogs(dataReferencia);
            downloadFile(blob, `relatorio_logs_${dataReferencia}.pdf`);
            setSnackbar({
                open: true,
                message: "Relatório de logs baixado com sucesso!",
                severity: "success",
                title: "Sucesso",
                position: { vertical: 'top', horizontal: 'right' }
            });
        } catch (error) {
            setSnackbar({
                open: true,
                message: error.message,
                severity: "error",
                title: "Erro",
                position: { vertical: 'top', horizontal: 'center' }
            });
        } finally {
            setIsLoading(false); // Oculta o FullScreenLoader
            setConfirmDialogOpen(false); // Fecha o diálogo após o download
        }
    };

    const columnsEnviados = [
      { field: 'envio', headerName: 'Envio',headerAlign: 'center', width: 80 },
      { field: 'data_referencia', headerName: 'Data de Referência', headerAlign: 'center',width: 150 },
      { field: 'data_envio', headerName: 'Data de Envio',headerAlign: 'center', width: 250 },
      { field: 'nome_cliente', headerName: 'Nome do Cliente',headerAlign: 'center', width: 550 },
      { field: 'cpf_cnpj', headerName: 'CPF/CNPJ',headerAlign: 'center', width: 150 },
      { field: 'email', headerName: 'Email',headerAlign: 'center', width: 300 },
      { field: 'codigo', headerName: 'Código',headerAlign: 'center', width: 100 },
      { field: 'corretora', headerName: 'Corretora',headerAlign: 'center', width: 100 },
      { field: 'Status', headerName: 'Status',headerAlign: 'center', width: 100 }
  ];
  
  const columnsNaoEnviados = [
      { field: 'envio', headerName: 'Envio', width: 80 },
      { field: 'data_referencia', headerName: 'Data de Referência',headerAlign: 'center', width: 150 },
      { field: 'nome_cliente', headerName: 'Nome do Cliente',headerAlign: 'center', width: 567 },
      { field: 'cpf_cnpj', headerName: 'CPF/CNPJ',headerAlign: 'center', width: 150 },
      { field: 'email', headerName: 'Email',headerAlign: 'center', width: 300 },
      { field: 'codigo', headerName: 'Código',headerAlign: 'center', flex:1},
      { field: 'corretora', headerName: 'Corretora',headerAlign: 'center', flex:1 },
  ];

    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom>Logs Envio Carteira Administrada</Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                    <InputLabel>Data de Referência (ANO-MES)</InputLabel>
                    <Select
                        value={dataReferencia}
                        onChange={handleDataChange}
                        label="Data de Referência (ANO-MES)"
                    >
                        <MenuItem value="">
                            <em>Selecione uma data</em>
                        </MenuItem>
                        {datasDisponiveis.map((data) => (
                            <MenuItem key={data} value={data}>
                                {data}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={fetchLogs}
                    disabled={!dataReferencia}
                >
                    Buscar Logs
                </Button>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
                <Button
                    variant={mostrarEnviados ? "contained" : "outlined"}
                    color="success"
                    onClick={() => setMostrarEnviados(true)}
                    disabled={!dataReferencia}
                >
                    Enviados
                </Button>
                <Button
                    variant={!mostrarEnviados ? "contained" : "outlined"}
                    color="error"
                    onClick={() => setMostrarEnviados(false)}
                    disabled={!dataReferencia}
                >
                    Não Enviados
                </Button>
            </Box>

            {loadingLogs ? (
                <LoadingIndicator message="Carregando logs..." />
            ) : (
                showTable && (
                  <>
                        <DataTable
                            columns={mostrarEnviados ? columnsEnviados : columnsNaoEnviados}
                            rows={mostrarEnviados 
                                ? logsEnviados.map((row, index) => ({ ...row, uniqueId: `${row.cpf_cnpj}-${index}` }))
                                : logsNaoEnviados.map((row, index) => ({ ...row, uniqueId: `${row.cpf_cnpj}-${index}` }))
                            }
                            idColumn="uniqueId" // Usa uniqueId como chave única para evitar duplicados
                            loading={loadingLogs}
                        />
                        {mostrarEnviados && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleDownloadClick}
                                sx={{ mt: 3 }}
                            >
                                Download Relatório de Logs
                            </Button>
                        )}
                    </>
                )
            )}


            <CustomDialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                title="Confirmação de Download"
                description={`Deseja baixar o relatório de logs para a data ${dataReferencia}?`}
                confirmButtonText="Confirmar"
                onConfirm={handleConfirmDownload}
            />

            <CustomSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                title={snackbar.title}
                onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
                position={snackbar.position}
            />

            <FullScreenLoader visible={isLoading} message="Gerando Relatório..." />
        </Box>
    );
};

export default CarteiraLogs;



