import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTheme } from '@mui/material/styles';

const EditButton = ({ onEdit, nome }) => {
  const theme = useTheme();

  return (
    <Tooltip title="Editar" arrow>
      <IconButton
        sx={{
          color:  theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main, // Ajuste de cor
        }}
        aria-label="editar"
        onClick={() => onEdit(nome)}
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
};

export default EditButton;
