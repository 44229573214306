// src/routes/adminRoutes.js
import React from 'react';
import AdminHome from '../pages/admin/AdminHome';
import UsersIndex from '../pages/admin/UsersIndex';
import UserData from '../pages/admin/UserData';
import UserCreate from '../pages/admin/UserCreate';
import UserUpdate from '../pages/admin/UserUpdate';
import CarteiraAdministrada from '../pages/admin/CarteiraAdministrada';
import CarteiraRelatorio from '../pages/admin/CarteiraRelatorio';
import CarteiraComentarioGestor from '../pages/admin/CarteiraComentarioGestor';
import CarteiraLogs from '../pages/admin/CarteiraLogs';
import CarteiraNaoEnviar from '../pages/admin/CarteiraNaoEnviar';

// Definição das rotas e sub-rotas
export const adminMenuItems = [
    { 
        text: 'Home', 
        route: '/admin/home',
        component: <AdminHome />,
    },
    { 
        text: 'Usuários', 
        route: '/admin/users_index',
        component: <UsersIndex />,
        submodules: [
          { text: 'Informações Usuários', route: '/admin/users/data', component: <UserData /> },
          { text: 'Criar Usuário', route: '/admin/users/create', component: <UserCreate /> },
          { text: 'Atualizar Usuário', route: '/admin/users/update', component: <UserUpdate /> },
        ]
    },
    { 
        text: 'Carteira Administrada', 
        route: '/admin/carteira-administrada',
        component: <CarteiraAdministrada />,
        submodules: [
          { text: 'Relatório', route: '/admin/carteira-administrada/report', component: <CarteiraRelatorio /> },
          { text: 'Comentário Gestor', route: '/admin/carteira-administrada/comentario_gestor', component: <CarteiraComentarioGestor /> },
          { text: 'Logs', route: '/admin/carteira-administrada/logs', component: <CarteiraLogs /> },
          { text: 'Não Enviar', route: '/admin/carteira-administrada/nao_enviar', component: <CarteiraNaoEnviar /> },
        ]
    },
];
