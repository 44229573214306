// context/AuthContext.jsx
import React, { createContext, useContext, useState,useCallback} from 'react';
import { login as loginService, logout as logoutService, checkAdminScope } from '../services/auth';  
import { apiWithAuth } from '../services/api'; 

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!sessionStorage.getItem('access_token'));
  const [isAdmin, setIsAdmin] = useState(null);

  // Função para verificar o escopo de admin apenas uma vez
  const verifyAdminStatus = useCallback(async () => {
    if (isAuthenticated && isAdmin === null) {
      // Aguardar o token ser renovado se `isRefreshing` estiver ativo
      await new Promise((resolve) => {
        const checkRefreshStatus = () => {
          if (!apiWithAuth.isRefreshing) resolve();
          else setTimeout(checkRefreshStatus, 50);
        };
        checkRefreshStatus();
      });

      try {
        const adminStatus = await checkAdminScope();
        setIsAdmin(adminStatus);
      } catch (error) {
        console.error('Erro ao verificar escopo de admin:', error);
        setIsAdmin(false);
      }
    }
  }, [isAuthenticated, isAdmin]);


  const login = async (username, password, isAdminLogin = false) => {
    try {
      const data = await loginService(username, password); // Faz o login padrão
      setIsAuthenticated(true);
      sessionStorage.setItem('access_token', data.access_token);
      sessionStorage.setItem('refresh_token', data.refresh_token);

      // Se é um login de administrador, verifica o escopo de admin
      if (isAdminLogin) {
        const adminCheck = await checkAdminScope(username, password);

        setIsAdmin(adminCheck);
        if (!adminCheck) throw new Error("Não autorizado para a área administrativa");

        // Redireciona para o painel de admin se tiver permissão
        window.location.href = '/admin/home';  
      } else {
        // Redireciona para o painel de usuário comum
        setIsAdmin(false);
        window.location.href = '/home';  
      }
    } catch (error) {
      setIsAuthenticated(false);
      setIsAdmin(false);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await logoutService(apiWithAuth);  
      setIsAuthenticated(false);
      setIsAdmin(false);
      sessionStorage.removeItem('access_token');
      sessionStorage.removeItem('refresh_token');
      window.location.href = '/login';
    } catch (error) {
      console.error('Erro no logout:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, isAdmin, login, logout ,verifyAdminStatus}}>
      {children}
    </AuthContext.Provider>
  );
};
