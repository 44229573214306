// UserCreate.jsx
import React, { useState } from 'react';
import { Box, Typography, TextField, Button, FormControl, FormControlLabel, Checkbox, FormLabel, Paper } from '@mui/material';
import { createUsers } from '../../services/auth';
import CustomSnackbar from '../../components/CustomSnackbar';

const UserCreate = () => {
  const [formData, setFormData] = useState({
    username: '',
    full_name: '',
    description: '',
    password: '',
    scopes: []
  });

  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success', title: '' });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleScopeChange = (event) => {
    const { checked, value } = event.target;
    setFormData((prevData) => {
      const newScopes = checked
        ? [...prevData.scopes, value]
        : prevData.scopes.filter((scope) => scope !== value);
      return { ...prevData, scopes: newScopes };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await createUsers(formData);
      setSnackbar({
        open: true,
        message: 'Usuário criado com sucesso!',
        severity: 'success',
        title: 'Sucesso',
        position: { vertical: 'top', horizontal: 'right' }
      });
      setFormData({
        username: '',
        full_name: '',
        description: '',
        password: '',
        scopes: []
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Erro ao criar usuário. Por favor, tente novamente.',
        severity: 'error',
        title: 'Erro',
        position: { vertical: 'top', horizontal: 'center' }
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: '#f4f6f8' }}>
      <Paper elevation={3} sx={{ width: '100%', p: 4, borderRadius: 2 }}>
        <Typography variant="h4" gutterBottom align="center">
          Criar Usuário
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="center">
          Criação de Usuários para acesso à dashboard.
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            helperText="Nome de Usuário"
          />
          <TextField
            label="Nome Completo"
            name="full_name"
            value={formData.full_name}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            helperText="Nome Completo"
          />
          <TextField
            label="Descrição"
            name="description"
            value={formData.description}
            onChange={handleChange}
            fullWidth
            margin="normal"
            helperText="Cargo/Função"
          />
          <TextField
            label="Senha"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            helperText="senha"
          />
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Escopo:</FormLabel>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.scopes.includes('read')}
                    onChange={handleScopeChange}
                    value="read"
                  />
                }
                label="Ler/Read"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.scopes.includes('write')}
                    onChange={handleScopeChange}
                    value="write"
                  />
                }
                label="Escrever/Write"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.scopes.includes('gerar_relatorio')}
                    onChange={handleScopeChange}
                    value="gerar_relatorio"
                  />
                }
                label="Gerar Relatório"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.scopes.includes('admin')}
                    onChange={handleScopeChange}
                    value="admin"
                  />
                }
                label="Admin"
              />
            </Box>
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3, borderRadius: 2, py: 1 }}
            disabled={loading}
          >
            {loading ? 'Criando...' : 'CRIAR USUÁRIO'}
          </Button>
        </form>
      </Paper>

      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        title={snackbar.title}
        onClose={handleCloseSnackbar}
        position={snackbar.position}
      />

    </Box>
  );
};

export default UserCreate;
