// components/FullScreenLoader.jsx
import React from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';

const FullScreenLoader = ({ message = 'Gerando Relatório...', visible = false }) => {
  const theme = useTheme();

  if (!visible) return null;

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      bottom={0}
      bgcolor="rgba(0, 0, 0, 0.6)"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      zIndex={1500} // Elevado para cobrir o conteúdo da página
    >
      <CircularProgress size={60} color="primary" />
      <Typography variant="h6" color={theme.palette.common.white} mt={2}>
        {message}
      </Typography>
    </Box>
  );
};

export default FullScreenLoader;
