import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

const CustomDialog = ({
    open,
    onClose,
    onConfirm,
    title,
    description,
    confirmButtonText = "Confirmar",
    cancelButtonText = "Cancelar",
    fields = [],
    onFieldChange,
}) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {description && (
                    <Typography variant="body1" gutterBottom>
                        {description}
                    </Typography>
                )}
                {fields.map((field) => (
                    <div key={field.name} style={{ marginBottom: 16 }}>
                        <Typography variant="body2" gutterBottom>
                            {field.label}
                        </Typography>
                        {field.type === "radio" ? (
                            <RadioGroup
                                value={field.value}
                                onChange={(e) => onFieldChange(field.name, e.target.value)}
                            >
                                {field.options.map((option) => (
                                    <FormControlLabel
                                        key={option.value}
                                        value={option.value}
                                        control={<Radio />}
                                        label={option.label}
                                    />
                                ))}
                            </RadioGroup>
                        ) : field.type === "file" ? (
                            <input
                                type="file"
                                accept={field.accept}
                                onChange={(e) => onFieldChange(field.name, e.target.files[0])}
                                style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
                            />
                        ) : (
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder={field.placeholder || ""}
                                type={field.type}
                                onChange={(e) => onFieldChange(field.name, e.target.value)}
                            />
                        )}
                    </div>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outlined" color="secondary">
                    {cancelButtonText}
                </Button>
                <Button onClick={onConfirm} variant="contained" color="primary">
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CustomDialog;
