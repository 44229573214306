// App.js
import React, { Suspense} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, Box } from '@mui/material';
import { ThemeProvider, useThemeContext } from './context/ThemeContext';
import { AuthProvider, useAuth } from './context/AuthContext';
import Login from './pages/Login';
import AdminLogin from './pages/admin/LoginAdmin';
import UserLayout from './layouts/UserLayout';
import AdminLayout from './layouts/AdminLayout';
import { adminMenuItems } from './routes/adminRoutes';
import AdminProtectedRoute from './routes/AdminProtectedRoute';

// Páginas importadas
const Home = React.lazy(() => import('./pages/Home'));
const Planejadores = React.lazy(() => import('./pages/Planejadores'));

// Fundos de Investimento
const FundosInvestimentos = React.lazy(() => import('./pages/fundosInvestimentos/FundosInvestimentos'));
const RankingPrev = React.lazy(() => import('./pages/fundosInvestimentos/RankingPrev'));
const RankingFund = React.lazy(() => import('./pages/fundosInvestimentos/RankingFund'));
const FundoRelatorioRisco = React.lazy(() => import('./pages/fundosInvestimentos/FundoRelatorioRisco'));
const ParametrosFundo = React.lazy(() => import('./pages/fundosInvestimentos/ParametrosFundo'));
const FundosAtencao = React.lazy(() => import('./pages/fundosInvestimentos/FundosAtencao'));
const BuscaFundos = React.lazy(() => import('./pages/fundosInvestimentos/BuscaFundos'));
const RelatorioPeriodico = React.lazy(() => import('./pages/RelatorioPeriodico'));

// Renda Variável
const RendaVariavel = React.lazy(() => import('./pages/rendaVariavel/RendaVariavel'));
const RendaVariavelFundos = React.lazy(() => import('./pages/rendaVariavel/RendaVariavelFundos'));
const RendaVariavelETF = React.lazy(() => import('./pages/rendaVariavel/RendaVariavelETF'));
const RendaVariavelStock = React.lazy(() => import('./pages/rendaVariavel/RendaVariavelStock'));
const RendaVariavelProventos = React.lazy(() => import('./pages/rendaVariavel/RendaVariavelProventos'));
const ClassificacaoRendaVariavelIndex = React.lazy(() => import('./pages/rendaVariavel/ClassificacaoRendaVariavelIndex'));
const AtivosClassificadosRendaVariavel = React.lazy(() => import('./pages/rendaVariavel/AtivosClassificadosRendaVariavel'));
const AtivosNaoClassificadosRendaVariavel = React.lazy(() => import('./pages/rendaVariavel/AtivosNaoClassificadosRendaVariavel'));

// Configurações de Renda Variável
const ConfiguracoesRendaVariavelIndex = React.lazy(() => import('./pages/rendaVariavel/Configuracoes/ConfiguracoesRendaVariavelIndex'));
const ConfiguracoesETF = React.lazy(() => import('./pages/rendaVariavel/Configuracoes/ConfiguracoesETF'));
const ConfiguracoesFundos = React.lazy(() => import('./pages/rendaVariavel/Configuracoes/ConfiguracoesFundos'));
const ConfiguracoesStock = React.lazy(() => import('./pages/rendaVariavel/Configuracoes/ConfiguracoesStock'));

// Credito Privado
const CreditoPrivado = React.lazy(() => import('./pages/creditoPrivado/CreditoPrivado'));
const CreditoPrivadoRanking = React.lazy(() => import('./pages/creditoPrivado/RankingCreditoPrivado'));
const ConfiguracoesCreditoPrivado = React.lazy(() => import('./pages/creditoPrivado/ConfiguracoesCreditoPrivado'))

// Definindo os temas para Light e Dark Mode com tipografia Montserrat
const getThemes = (darkMode) => ({
  lightTheme: createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#2C91AD',  // Teal Principal
        light: '#54B8D3',  // Teal Light
        dark: '#206A7F',   // Teal Dark
        contrastText: '#FFFFFF',  // Branco
      },
      secondary: {
        main: '#29A152',  // Green Principal
        light: '#58D493',  // Green Light
        dark: '#1D7246',   // Green Dark
      },
      background: {
        default: '#F5F7FA',  // Gray 800
        paper: '#FFFFFF',    // Branco
      },
      text: {
        primary: '#1D1D26',  // Gray 100
        secondary: '#4D5766',  // Gray 300
      },
      auxiliary: {
        negative: '#E51739',    // Negative (erro)
        warning: '#F27A00',     // Warning (alerta)
        informational: '#29A152',  // Informational (informação)
        successful: '#29A152',  // Successful (sucesso)
      },
    },
    typography: {
      fontFamily: 'Montserrat, sans-serif',
      h4: {
        fontWeight: 700,
      },
      body1: {
        fontWeight: 400,
      },
    },
  }),
  darkTheme: createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#4A4A4A',  // Cinza médio para elementos primários
        light: '#707070', // Cinza claro para hover
        dark: '#1E1E1E',  // Cinza escuro para fundo
        contrastText: '#FFFFFF', // Texto branco
      },
      secondary: {
        main: '#03DAC6',  // Verde suave para destacar
        light: '#66FFF9', // Verde mais claro no hover
        dark: '#00A396',  // Verde escuro para botões ou destaques
      },
      background: {
        default: '#121212',  // Fundo preto suave
        paper: '#1F1F1F',    // Fundo cinza escuro para cartões e elementos
      },
      text: {
        primary: '#E0E0E0',  // Branco suave
        secondary: '#B0BEC5', // Cinza claro para texto secundário
      },
      auxiliary: {
        negative: '#E51739',    // Negative (erro)
        warning: '#F27A00',     // Warning (alerta)
        informational: '#2983CC',  // Informational (informação)
        successful: '#29A152',  // Successful (sucesso)
      },
    },
    typography: {
      fontFamily: 'Montserrat, sans-serif',
      h4: {
        fontWeight: 700,
      },
      body1: {
        fontWeight: 400,
      },
    },
  }),
});



const AppContent = () => {
  const { darkMode } = useThemeContext();
  const { isAuthenticated, isAdmin } = useAuth();
  const { lightTheme, darkTheme } = getThemes(darkMode);

  return (
    <MUIThemeProvider theme={darkMode ? darkTheme: lightTheme}>
      <CssBaseline />
      <Router>
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              {/* Rotas de Login */}
              <Route path="/login" element={<Login />} />
              <Route path="/admin/login_admin" element={<AdminLogin />} />

              {/* Redirecionamento baseado em autenticação */}
              <Route path="/" element={isAuthenticated ? (isAdmin ? <Navigate to="/admin/home" /> : <Navigate to="/home" />) : <Navigate to="/login" />} />

              {/* Layout e rotas de usuários comuns */}
              {isAuthenticated  && (
                <Route element={<UserLayout />}>
                  <Route path="/home" element={<Home />} />
                  <Route path="/planejadores" element={<Planejadores />} />
                  <Route path="/report" element={<RelatorioPeriodico />} />
                  <Route path="/fundos-investimentos" element={<FundosInvestimentos />} />
                  <Route path="/fundos-investimentos/ranking_fund" element={<RankingFund />} />
                  <Route path="/fundos-investimentos/ranking_prev" element={<RankingPrev />} />
                  <Route path="/fundos-investimentos/parametros" element={<ParametrosFundo />} />
                  <Route path="/fundos-investimentos/fundos_atencao" element={<FundosAtencao />} />
                  <Route path="/fundos-investimentos/busca_fundos" element={<BuscaFundos />} />
                  <Route path="/fundos-investimentos/relatorio_risco" element={<FundoRelatorioRisco />} />
                  <Route path="/renda-variavel" element={<RendaVariavel />} />
                  <Route path="/renda-variavel/classificacao" element={<ClassificacaoRendaVariavelIndex />}>
                    <Route path="classificados" element={<AtivosClassificadosRendaVariavel />} />
                    <Route path="nao-classificados" element={<AtivosNaoClassificadosRendaVariavel />} />
                  </Route>
                  <Route path="/renda-variavel/configuracoes" element={<ConfiguracoesRendaVariavelIndex />}>
                    <Route path="fundos" element={<ConfiguracoesFundos />} />
                    <Route path="etf" element={<ConfiguracoesETF />} />
                    <Route path="stock" element={<ConfiguracoesStock />} />
                  </Route>
                  <Route path="/renda-variavel/fundos_bolsa" element={<RendaVariavelFundos />} />
                  <Route path="/renda-variavel/etf" element={<RendaVariavelETF />} />
                  <Route path="/renda-variavel/acoes_bdrs" element={<RendaVariavelStock />} />
                  <Route path="/renda-variavel/proventos" element={<RendaVariavelProventos />} />
                  <Route path="/credito_privado" element={<CreditoPrivado />} />
                  <Route path="/credito_privado/ranking" element={<CreditoPrivadoRanking />} />
                  <Route path="/credito_privado/configuracoes" element={<ConfiguracoesCreditoPrivado />} />
                </Route>
              )}


              {/* Teste */}
              {/* Layout e rotas de administradores */}
              {isAuthenticated && (
                <Route element={<AdminLayout />}>
                  {adminMenuItems.map((item) => (
                    <Route
                      key={item.route}
                      path={item.route}
                      element={<AdminProtectedRoute>{item.component}</AdminProtectedRoute>}
                    />
                  ))}
                  {adminMenuItems.flatMap(item =>
                    item.submodules?.map(sub => (
                      <Route
                        key={sub.route}
                        path={sub.route}
                        element={<AdminProtectedRoute>{sub.component}</AdminProtectedRoute>}
                      />
                    ))
                  )}
                </Route>
              )}

              {/* Redirecionamento padrão para usuários não autenticados */}
              {!isAuthenticated && <Route path="*" element={<Navigate to="/login" />} />}
            </Routes>
          </Suspense>
        </Box>
      </Router>
    </MUIThemeProvider>
  );
};

const App = () => (
  <ThemeProvider>
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  </ThemeProvider>
);

export default App;



