// src/pages/admin/AdminHome.jsx
import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import ModuleCard from '../../components/ModuleCard'; // Importa o componente de card
import { adminMenuItems } from '../../routes/adminRoutes'; // Importa as rotas administrativas
import ArticleSharpIcon from '@mui/icons-material/ArticleSharp';

// Define os ícones para os módulos
const moduleIcons = {
  'Usuários': <PeopleIcon sx={{ fontSize: 60 }} />,
  'Carteira Administrada': <ArticleSharpIcon sx={{ fontSize: 60 }} />,
};

const AdminHome = ({ cardsPerRow = {  md: 1} }) => {
  const theme = useTheme(); // Obtém o tema atual

  // Filtra os módulos principais (excluindo "Home")
  const topModules = adminMenuItems
    .filter((item) => item.text !== 'Home') // Remove o item "Home"
    .map((item) => ({
      text: item.text,
      description: `Gerencie o módulo de ${item.text}.`,
      route: item.route,
    }));

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
        Módulos Administrativos
      </Typography>

      {/* Flexbox layout responsivo */}
      <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          md: `repeat(${cardsPerRow.md}, 1fr)`,   // Em telas maiores, 1cards por linha
        },
        gap: 3, // Espaçamento entre os cards
        paddingX: 2, // Padding lateral para evitar que os cards fiquem encostados nas bordas em telas pequenas
      }}
    >
        {topModules.map((module, index) => (
          <ModuleCard
            key={index}
            title={module.text}
            description={module.description}
            icon={moduleIcons[module.text] || <PeopleIcon sx={{ fontSize: 60 }} />} // Ícone dinâmico baseado no nome do módulo
            route={module.route}
          />
        ))}
      </Box>
    </Box>
  );
};

export default AdminHome;
