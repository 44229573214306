import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';

const RemoveButton = ({ onRemove, nome }) => {
  const theme = useTheme();
  
  return (
    <Tooltip title="Remover" arrow>
      <IconButton
        sx={{
          color: theme.palette.mode === 'dark' ? '#8B0000' : 'red',
        }}
        aria-label="remover"
        onClick={() => onRemove(nome)}
      >
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};

export default RemoveButton;
