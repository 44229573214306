import React, { useState, useCallback } from 'react';
import { Drawer, List, ListItem, ListItemText, Collapse, Divider, Box, Tooltip, IconButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import DarkModeSwitch from './DarkModeSwitch'; // Switch do Dark Mode
import LogoutButton from './LogoutButton'; // Botão de Logout
import { menuItems } from '../routes/routes'; // Importa as rotas centralizadas

// Componente Módulo (Com submódulos)
const ModuleItem = React.memo(({ item, openModule, handleExpandClick, handleDoubleClick, handleDrawerClose, navigate }) => {
  const theme = useTheme();

  return (
    <>
      <ListItem
        button="true"
        onClick={() => { 
          if (item.submodules) {
            handleExpandClick(item.text);
          } else {
            navigate(item.route);
            handleDrawerClose();
          }
        }}
        onDoubleClick={() => { 
          if (item.submodules) {
            handleDoubleClick(item.route);
            handleDrawerClose();
          }
        }}
        sx={{
          color: theme.palette.text.primary,
          cursor: item.submodules ? 'pointer' : 'default', // Muda o cursor se houver submódulos
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
        }}
      >
        <ListItemText primary={item.text} />
        {item.submodules && (
          <IconButton
            edge="end"
            onClick={(e) => {
              e.stopPropagation();
              handleExpandClick(item.text);
            }}
          >
            {openModule === item.text ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </ListItem>
      {/* Submódulos */}
      {item.submodules && (
        <Collapse in={openModule === item.text} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.submodules.map((submodule, subIndex) => (
              <ListItem
                key={subIndex}
                button="true"
                sx={{ 
                  pl: 4,
                  color: theme.palette.text.secondary,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
                onClick={() => { 
                  navigate(submodule.route);
                  handleDrawerClose();
                }}
              >
                <ListItemText primary={submodule.text} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
});

const Sidebar = ({ isDrawerOpen, toggleDrawer, darkMode, toggleDarkMode }) => {
  const [openModule, setOpenModule] = useState(null);
  const theme = useTheme();
  const navigate = useNavigate(); // Definir o navigate aqui

  // Memorizar funções de manipulação de eventos
  const handleExpandClick = useCallback((module) => {
    setOpenModule((prev) => (prev === module ? null : module));
  }, []);

  const handleDoubleClick = useCallback((route) => {
    navigate(route);
    toggleDrawer();
  }, [navigate, toggleDrawer]);

  const handleDrawerClose = useCallback(() => {
    setOpenModule(null);  // Retrái todos os módulos ao fechar o sidebar
    toggleDrawer();
  }, [toggleDrawer]);

  return (
    <Drawer
      anchor="left"
      open={isDrawerOpen}
      onClose={handleDrawerClose}
      sx={{ 
        '& .MuiDrawer-paper': { 
          width: 240, 
          backgroundColor: theme.palette.background.paper,
        } 
      }}
    >
      <List>
        {menuItems.map((item, index) => (
          <React.Fragment key={index}>
            <ModuleItem
              item={item}
              openModule={openModule}
              handleExpandClick={handleExpandClick}
              handleDoubleClick={handleDoubleClick}
              handleDrawerClose={handleDrawerClose}
              navigate={navigate} // Passar o navigate como prop
            />
            <Divider />
          </React.Fragment>
        ))}

        {/* Dark Mode Switch e Logout dentro do Sidebar */}
        <Box 
          sx={{ 
            display: { xs: 'flex', md: 'none' }, // Mostra apenas em telas pequenas
            alignItems: 'center', 
            justifyContent: 'center', 
            gap: 1, 
            p: 2, 
            mt: 'auto',  // Garante que eles fiquem na parte inferior do Sidebar
            backgroundColor: theme.palette.background.default 
          }}
        >
          <Tooltip title="Modo Escuro" arrow>
            <span> {/* <span> usado para garantir um único filho para Tooltip */}
              <DarkModeSwitch darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
            </span>
          </Tooltip>
          <Tooltip title="Logout" arrow>
            <span> {/* <span> usado para garantir um único filho para Tooltip */}
              <LogoutButton />
            </span>
          </Tooltip>
        </Box>
      </List>
    </Drawer>
  );
};

export default Sidebar;
