import React from 'react';
import { Switch, Box, Tooltip } from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material'; // Ícones para Dark e Light Mode

const DarkModeSwitch = ({ darkMode, toggleDarkMode }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {/* Ícone de Sol para Light Mode com tamanho ajustado */}
      <Brightness7 sx={{ color: darkMode ? 'gray' : '#FFD700', fontSize: 20 }} /> {/* Ícone menor no Light Mode */}
      
      {/* Tooltip para adicionar label ao passar o mouse */}
      <Tooltip title={darkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'} arrow>
        <Switch
          checked={darkMode}
          onChange={toggleDarkMode}
          size="small"  // Diminui o tamanho do Switch
          id="darkModeSwitch"  // Adiciona um id
          name="darkMode"  // Adiciona um name
          aria-label="Toggle dark mode"  // Adiciona um aria-label para acessibilidade
          sx={{
            '& .MuiSwitch-switchBase.Mui-checked': {
              color: darkMode ? '#FFD700' : '#2C91AD',  // Cor do thumb (botão) no modo dark e light
            },
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
              backgroundColor: darkMode ? '#FFD700' : '#2C91AD',  // Cor do track no modo dark e light
            },
            '& .MuiSwitch-switchBase': {
              color: '#0056b3',  // Cor do thumb no light mode para contraste melhor
            },
            '& .MuiSwitch-track': {
              backgroundColor: '#b0bec5',  // Cor neutra para o track no light mode
            },
          }}
        />
      </Tooltip>

      {/* Ícone de Lua para Dark Mode com tamanho ajustado */}
      <Brightness4 sx={{ color: darkMode ? '#FFD700' : 'gray', fontSize: 20 }} /> {/* Ícone menor no Dark Mode */}
    </Box>
  );
};

export default DarkModeSwitch;
