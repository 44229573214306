import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, CircularProgress, IconButton, Tooltip } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InfoIcon from '@mui/icons-material/Info';
import { uploadFileComentarioGestor, fetchComentariosGestor, downloadComentarioGestor, deleteComentarioGestor } from '../../services/clientes';
import { downloadFile } from '../../components/utils/downloadUtils';
import DataTable from '../../components/DataTable';
import CustomDialog from '../../components/Modal/CustomDialog';
import CustomSnackbar from '../../components/CustomSnackbar';
import RemoveButton from '../../components/ActionButton/RemoveButton';
import DownloadButton from '../../components/ActionButton/DownloadButton';
import LoadingIndicator from '../../components/LoadingIndicator';

const CarteiraComentarioGestor = () => {
    const [comentarios, setComentarios] = useState([]);
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success', title: '' });
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [dialogAction, setDialogAction] = useState({ action: null, nomeArquivo: '' });
    const [openInstructionsDialog, setOpenInstructionsDialog] = useState(false);

    useEffect(() => {
        loadComentarios();
    }, []);

    const loadComentarios = async () => {
        setLoading(true);
        try {
            const data = await fetchComentariosGestor();
            const formattedComentarios = data.files.map((file) => ({ nome: file }));
            setComentarios(formattedComentarios);
        } catch (error) {
            setSnackbar({
                open: true,
                message: "Erro ao carregar os comentários do gestor. Tente novamente mais tarde.",
                severity: "error",
                title: "Erro",
                position: { vertical: 'top', horizontal: 'center' }
            });
        } finally {
            setLoading(false);
        }
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file || file.type !== 'text/plain') {
            setSnackbar({
                open: true,
                message: "Arquivo inválido. Apenas arquivos .txt são permitidos.",
                severity: "error",
                title: "Erro",
                position: { vertical: 'top', horizontal: 'center' }
            });
            return;
        }

        setUploading(true);
        try {
            await uploadFileComentarioGestor(file);
            setSnackbar({
                open: true,
                message: "Arquivo enviado com sucesso!",
                severity: "success",
                title: "Sucesso",
                position: { vertical: 'top', horizontal: 'right' }
            });
            loadComentarios();
        } catch (error) {
            setSnackbar({
                open: true,
                message: error.message,
                severity: "error",
                title: "Erro",
                position: { vertical: 'top', horizontal: 'center' }
            });
        } finally {
            setUploading(false);
        }
    };

    const handleDownload = (nomeArquivo) => {
        openConfirmDialog('download', nomeArquivo);
    };

    const executeDownload = async (nomeArquivo) => {
        try {
            const blob = await downloadComentarioGestor(nomeArquivo);
            downloadFile(blob, nomeArquivo);
            setSnackbar({
                open: true,
                message: "Download iniciado com sucesso!",
                severity: "success",
                title: "Sucesso",
                position: { vertical: 'top', horizontal: 'right' }
            });
        } catch (error) {
            setSnackbar({
                open: true,
                message: error.message,
                severity: "error",
                title: "Erro",
                position: { vertical: 'top', horizontal: 'center' }
            });
        }
    };

    const handleRemove = (nomeArquivo) => {
        openConfirmDialog('delete', nomeArquivo);
    };

    const openConfirmDialog = (action, nomeArquivo) => {
        setDialogAction({ action, nomeArquivo });
        setConfirmDialogOpen(true);
    };

    const closeConfirmDialog = () => {
        setConfirmDialogOpen(false);
    };

    const handleConfirmAction = async () => {
        if (dialogAction.action === 'delete') {
            try {
                await deleteComentarioGestor(dialogAction.nomeArquivo);
                setSnackbar({
                    open: true,
                    message: "Arquivo removido com sucesso!",
                    severity: "success",
                    title: "Sucesso",
                    position: { vertical: 'top', horizontal: 'right' }
                });
                loadComentarios();
            } catch (error) {
                setSnackbar({
                    open: true,
                    message: error.message,
                    severity: "error",
                    title: "Erro",
                    position: { vertical: 'top', horizontal: 'center' }
                });
            }
        } else if (dialogAction.action === 'download') {
            await executeDownload(dialogAction.nomeArquivo);
        }
        closeConfirmDialog();
    };

    const handleOpenInstructionsDialog = () => setOpenInstructionsDialog(true);
    const handleCloseInstructionsDialog = () => setOpenInstructionsDialog(false);

    const columns = [
        { field: 'nome', headerName: 'Nome do Arquivo', headerAlign: 'center', flex: 1 },
        {
            field: 'acoes',
            headerName: 'Ações',
            headerAlign: 'center',
            renderCell: (params) => (
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <DownloadButton onDownload={handleDownload} nome={params.row.nome} />
                    <RemoveButton onRemove={handleRemove} nome={params.row.nome} />
                </Box>
            ),
            flex: 0.5
        }
    ];

    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom>Comentários do Gestor</Typography>
            
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                <Button
                    variant="contained"
                    component="label"
                    startIcon={<UploadFileIcon />}
                    disabled={uploading}
                >
                    Adicionar Arquivo .txt
                    <input
                        type="file"
                        accept=".txt"
                        hidden
                        onChange={handleFileUpload}
                    />
                </Button>
                <Tooltip title="Instruções para o arquivo">
                    <IconButton onClick={handleOpenInstructionsDialog}>
                        <InfoIcon color="secondary" />
                    </IconButton>
                </Tooltip>
                {uploading && <CircularProgress size={24} />}
            </Box>

            {loading ? (
                <LoadingIndicator message="Carregando dados ..." />
            ) : (
                <DataTable
                    columns={columns}
                    rows={comentarios}
                    idColumn="nome"
                    loading={loading}
                />
            )}

            <CustomDialog
                open={confirmDialogOpen}
                onClose={closeConfirmDialog}
                title={dialogAction.action === 'delete' ? "Confirmar Remoção" : "Confirmar Download"}
                description={`Tem certeza de que deseja ${dialogAction.action === 'delete' ? 'remover' : 'baixar'} o arquivo "${dialogAction.nomeArquivo}"?`}
                confirmButtonText="Confirmar"
                onConfirm={handleConfirmAction}
            />

            <CustomDialog
                open={openInstructionsDialog}
                onClose={handleCloseInstructionsDialog}
                title="Instruções para o Arquivo .txt"
                description={
                    <>
                        <span 
                            style={{ 
                                display: 'block', 
                                lineHeight: 1.8, 
                                fontSize: '1.1rem', 
                                fontWeight: 'bold', 
                                color: '#1976d2', 
                                marginBottom: '8px' 
                            }}
                        >
                            Formato do Arquivo: <span style={{ color: '#000' }}>comentario_gestor_ANO-MES.txt</span>
                        </span>
                        <span 
                            style={{ 
                                display: 'block', 
                                lineHeight: 1.8, 
                                fontSize: '1rem', 
                                marginTop: '10px', 
                                color: '#333' 
                            }}
                        >
                            Dentro do arquivo, as quebras de linha devem ocorrer apenas entre parágrafos.
                        </span>
                    </>
                }
                confirmButtonText="Entendi"
                cancelButtonText="Cancelar"
                onConfirm={handleCloseInstructionsDialog}
                confirmButtonStyle={{
                    backgroundColor: '#1976d2',
                    color: 'white',
                    fontWeight: 'bold',
                    '&:hover': {
                    backgroundColor: '#1565c0',
                    }
                }}
                cancelButtonStyle={{
                    color: '#1976d2',
                    fontWeight: 'bold',
                    '&:hover': {
                    backgroundColor: 'rgba(25, 118, 210, 0.1)',
                    }
                }}
            />


            <CustomSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                title={snackbar.title}
                onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
                position={snackbar.position}
            />
        </Box>
    );
};

export default CarteiraComentarioGestor;
