// admin/UserData.jsx
import React, { useEffect, useState } from 'react';
import { fetchUsers } from '../../services/auth';
import DataTable from '../../components/DataTable';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails  } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const UserData = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const userData = await fetchUsers();
        console.log(userData);
        setUsers(userData);
      } catch (error) {
        console.error("Erro ao carregar dados dos usuários:", error);
      } finally {
        setLoading(false);
      }
    };
    
    loadUsers();
  }, []);

  const translateScope = (scope) => {
    const translations = {
      read: 'ler',
      write: 'escrever',
      gerar_relatorio: 'gerar relatório',

    };
    return translations[scope] || scope;
  };

  const columns = [
    { field: 'username', headerName: 'Nome de Usuário', flex: 1 },
    { field: 'full_name', headerName: 'Nome Completo', flex: 1 },
    { field: 'description', headerName: 'Descrição', flex: 1 },
    {
      field: 'scopes',
      headerName: 'Escopos',
      flex: 2,
      renderCell: (params) => {
        const scopes = params.row?.scopes;
        return Array.isArray(scopes) && scopes.length > 0
          ? scopes.map(translateScope).join(', ')
          : 'N/A';
      },
    },
    { field: 'disabled', headerName: 'Inativo', flex: 1, type: 'boolean' }
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Users
      </Typography>
      <DataTable
        columns={columns}
        rows={users}
        idColumn="username"
        loading={loading}
      />

      <Accordion sx={{ mb: 2 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1"><strong>Obs:</strong> Escopos que um usuário pode ter</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            <strong>Ler:</strong> Só pode visualizar o conteúdo (GET).
          </Typography>
          <Typography variant="body2">
            <strong>Escrever:</strong> Pode enviar informações para serem atualizadas (POST).
          </Typography>
          <Typography variant="body2">
            <strong>Admin:</strong> Tem acesso à dashboard de admin. Pode criar e atualizar dados de usuários.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default UserData;
