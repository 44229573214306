import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { menuItems } from '../routes/routes'; // Importa as rotas centralizadas

const NavBar = () => {
  const navigate = useNavigate();
  const theme = useTheme(); // Usa o tema para cores dinâmicas

  return (
    <Box sx={{ display: 'flex', gap: 4 }}>
      {menuItems.map((item, index) => (
        <Typography
          key={index}
          onClick={() => navigate(item.route)}
          sx={{
            cursor: 'pointer',
            padding: '6px 0',
            '&:hover': { color: theme.palette.secondary.light }, // Aplica hover com a cor secundária do tema
            transition: 'color 0.2s',
          }}
        >
          {item.text}
        </Typography>
      ))}
    </Box>
  );
};

export default NavBar;
