// UserUpdate.jsx
import React, { useState } from 'react';
import { Box, Typography, TextField, Button, FormControl, FormControlLabel, Radio, RadioGroup, FormLabel, Paper, Checkbox } from '@mui/material';
import { updateUser } from '../../services/auth';
import CustomSnackbar from '../../components/CustomSnackbar';

const UserUpdate = () => {
  const [formData, setFormData] = useState({
    username: '',
    full_name: '',
    description: '',
    password: '',
    scopes: [],
    disabled: null // Valor inicial `null` para indicar ausência de seleção
  });

  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success', title: '' });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleRadioChange = (event) => {
    const value = event.target.value === 'true' ? true : false;
    setFormData((prevData) => ({ ...prevData, disabled: value }));
  };

  const handleScopeChange = (event) => {
    const { checked, value } = event.target;
    setFormData((prevData) => {
      const newScopes = checked
        ? [...prevData.scopes, value]
        : prevData.scopes.filter((scope) => scope !== value);
      return { ...prevData, scopes: newScopes };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      // Cria o objeto `updateData` contendo apenas os campos preenchidos/modificados
      const updateData = {};
      if (formData.full_name) updateData.full_name = formData.full_name;
      if (formData.description) updateData.description = formData.description;
      if (formData.password) updateData.password = formData.password;
      if (formData.scopes.length > 0) updateData.scopes = formData.scopes;
      if (formData.disabled !== null) updateData.disabled = formData.disabled; // Inclui `disabled` apenas se for `true` ou `false`

      await updateUser(formData.username, updateData);

      setSnackbar({
        open: true,
        message: 'Usuário atualizado com sucesso!',
        severity: 'success',
        title: 'Sucesso'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Erro ao atualizar usuário. Por favor, tente novamente.',
        severity: 'error',
        title: 'Erro'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: '#f4f6f8' }}>
      <Paper elevation={3} sx={{ width: '100%',  p: 4, borderRadius: 2 }}>
        <Typography variant="h4" gutterBottom align="center">
          Atualizar Usuário
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="center">
          Atualização dos dados do Usuário da dashboard. A partir do username/nome de usuário da pessoa.
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            helperText="Nome de Usuário"
            disabled={loading}
          />
          <TextField
            label="Nome Completo"
            name="full_name"
            value={formData.full_name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            helperText="Nome Completo"
          />
          <TextField
            label="Descrição"
            name="description"
            value={formData.description}
            onChange={handleChange}
            fullWidth
            margin="normal"
            helperText="Cargo/Função"
          />
          <TextField
            label="Senha"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            fullWidth
            margin="normal"
            helperText="senha"
          />
          <FormControl component="fieldset" margin="normal" >
            <FormLabel component="legend">Status:</FormLabel>
            <RadioGroup
              row
              name="status"
              value={formData.disabled === null ? '' : formData.disabled.toString()}
              onChange={handleRadioChange}
            >
              <FormControlLabel value="false" control={<Radio />} label="Ativo" />
              <FormControlLabel value="true" control={<Radio />} label="Inativo" />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset" margin="normal" >
            <FormLabel component="legend">Escopo:</FormLabel>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, ml: 3, mt: 1 }}>
              <FormControlLabel
                control={<Checkbox checked={formData.scopes.includes('read')} onChange={handleScopeChange} value="read" />}
                label="Ler/Read"
              />
              <FormControlLabel
                control={<Checkbox checked={formData.scopes.includes('write')} onChange={handleScopeChange} value="write" />}
                label="Escrever/Write"
              />
              <FormControlLabel
                control={<Checkbox checked={formData.scopes.includes('gerar_relatorio')} onChange={handleScopeChange} value="gerar_relatorio" />}
                label="Gerar Relatório"
              />
              <FormControlLabel
                control={<Checkbox checked={formData.scopes.includes('admin')} onChange={handleScopeChange} value="admin" />}
                label="Admin"
              />
            </Box>
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3, borderRadius: 2, py: 1 }}
            disabled={loading}
          >
            {loading ? 'Atualizando...' : 'Atualizar Usuário'}
          </Button>
        </form>
      </Paper>

      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        title={snackbar.title}
        onClose={handleCloseSnackbar}
      />
    </Box>
  );
};

export default UserUpdate;
