import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const AdminProtectedRoute = ({ children }) => {
  const { isAuthenticated, isAdmin, verifyAdminStatus } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAdmin = async () => {
      if (isAuthenticated && isAdmin === null) {
        await verifyAdminStatus();
      }
      setLoading(false);
    };

    checkAdmin();
  }, [isAuthenticated, isAdmin, verifyAdminStatus]);

  if (!isAuthenticated) {
    return <Navigate to="/admin/login_admin" replace />;
  }

  if (loading) {
    return <div>Carregando...</div>;
  }

  return isAdmin ? children : <Navigate to="/home" replace />;
};

export default AdminProtectedRoute;
