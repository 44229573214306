import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';  // Ícone de logout
import { useAuth } from '../context/AuthContext';  // Importa o AuthContext para gerenciar o logout

const LogoutButton = () => {
  const { logout } = useAuth();  // Obtém a função de logout do AuthContext

  const handleLogout = async () => {
    try {
      await logout();  // Chama a função de logout do contexto
    } catch (error) {
      console.error('Erro ao deslogar:', error);
    }
  };

  return (
    <Tooltip title="Logout" arrow>
      <IconButton color="inherit" onClick={handleLogout}>
        <ExitToAppIcon />  {/* Ícone de logout */}
      </IconButton>
    </Tooltip>
  );
};

export default LogoutButton;
