import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { useAuth } from '../../context/AuthContext';  // Usa o hook useAuth para acessar o contexto
import loginImage from '../../assets/img/mont-capital-logo.png'; 
import { keyframes } from '@emotion/react'; 
import CustomSnackbar from '../../components/CustomSnackbar'; // Importa o CustomSnackbar para exibir mensagens

// Animação de FadeIn
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AdminLogin = () => {
  const { login } = useAuth();  // Usa o hook useAuth
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(username, password,true);  // Chama a função de login do contexto
      setSnackbar({ 
        open: true,
        message: 'Login realizado com sucesso!',
        severity: 'success', title:"Sucesso",
        position :{ vertical: 'top', horizontal: 'right' } });

    } catch (error) {
      setErrorMessage('Falha no login. Verifique suas credenciais.');
      // setSnackbar({ open: true, message:  error.message, severity: 'error' , title:"Erro", position :{ vertical: 'top', horizontal: 'center' }});
      let message = 'Falha no login. Verifique usuário e a senha.';
      let title = 'Erro de Autenticação';

      if (error.message === 'User is disabled.') {
        message = 'Sua conta está desativada. Entre em contato com o administrador.';
        title = 'Usuário Desativado';
      } else if (error.message === 'Server is currently unavailable. Please try again later.') {
        message = 'Erro de conexão com o servidor. Tente novamente mais tarde.';
        title = 'Erro de Servidor';
      }

      setSnackbar({ open: true, message, severity: 'error', title ,position :{ vertical: 'top', horizontal: 'center' }});
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f0f0f0',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: 400,
          padding: '40px',
          border: '1px solid #ddd',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
          borderRadius: '10px',
          backgroundColor: 'white',
          textAlign: 'center',
          animation: `${fadeIn} 1s ease-in-out`,
        }}
      >
        {/* Imagem de Login */}
        <img
          src={loginImage}
          alt="Login"
          style={{
            width: '100%',
            maxWidth: '250px',
            height: 'auto',
            marginBottom: '40px',
          }}
        />

        {/* Texto "Admin" com cor do tema */}
        <Typography 
          variant="h4" 
          color="primary"  // Cor do tema
          sx={{ marginBottom: '20px' }}  // Espaçamento abaixo do texto
        >
          Admin
        </Typography>

        {/* Formulário de Login */}
        <form onSubmit={handleSubmit}>
          <TextField
            label="Username"
            variant="outlined"
            margin="normal"
            fullWidth
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}  // Captura o username
          />
          <TextField
            label="Password"
            variant="outlined"
            margin="normal"
            type="password"
            fullWidth
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}  // Captura o password
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
          >
            Login
          </Button>
        </form>

        {errorMessage && (
          <Typography color="error" sx={{ mt: 2 }}>
            {errorMessage}
          </Typography>
        )}

        {/* Link para acesso de usuário */}
        <Typography sx={{ mt: 3 }}>
          <a href="/login" style={{ textDecoration: 'underline',cursor: 'pointer',   color: '#0056b3' }}>
           Voltar para o Login de Usuário
          </a>
        </Typography>
      </Box>

      {/* CustomSnackbar para exibir mensagens */}
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        title={snackbar.title}
        position={snackbar.position}
        onClose={handleCloseSnackbar}
      />
    </Box>
  );
};

export default AdminLogin;

